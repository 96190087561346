import React, { useEffect, useRef, useState } from "react";
import ExchangeKycHeader from "../Common/ExchangeHeader";
import TradingViewChart from "./TradingView";
import { Link, useParams, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, socketUrl } from "../Common/BaseUrl";
import $ from "jquery";
import Moment from "moment";
import MarketTrade from "./MarketTrade";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { ProductName } from "../Common/ProductName";
import io from "socket.io-client";
import DocumentMeta from "react-document-meta";
import BigNumber from "bignumber.js";
import TopBar from "./TopBar";
import MarketDepth from "./MarketDepth";
import BuyBox from "./BuyBox";
import OrderHistory from "./OrderHistory";
import SpotBalance from "./SpotBalance";
import BuyOrder from "./BuyOrder";
import SellOrder from "./SellOrder";
import {
  addFavCoin,
  cancleBack,
  checkFavCoin,
  checkPair,
  checkPairExchange,
  deleteOrderFun,
  generatePayId,
  getCryptoDataByTab,
  getOpenPair,
  getOrder,
  orderCreate,
} from "../services/ExchangeServices";
import { userData } from "../services/User";
import SellBox from "./SellBox";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../utils/decimalFormatter";
import { styled } from "styletron-react";

const ExchnageFuncSocket = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const clientRef = useRef(null);
  const binanceWebsocket = useRef(null);
  const chartRef = useRef(null);
  const marketRef = useRef(null);
  const buy_sellRef = useRef(null);
  const tradesRef = useRef(null);
  const myordersRef = useRef(null);
  const params = useParams();
  const token = localStorage.getItem("token");
  const [firstCoin, setFirstCoin] = useState("");
  const [isActive, setIsActive] = useState("N");
  const [secondCoin, setSecondCoin] = useState("");
  const [setUrl, setSetUrl] = useState("");
  const [exchange_tab, setExchange_tab] = useState([]);
  const [coinListData, setCoinListData] = useState([]);
  const [exchange, setExchange] = useState("");
  const [topBarStatus, setTopBarStatus] = useState(false);
  const [topBarApiData, setTopBarApiData] = useState([]);
  const [buyAmount, setBuyAmount] = useState("");
  const [setActiveTab, setSetActiveTab] = useState(params.secondCoin);
  const [buy_order_total, setBuy_order_total] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [coinListStatus, setCoinListStatus] = useState(false);
  const [mySpotBalance, setMySpotBalance] = useState([]);
  const [firstCoinNew, setFirstCoinNew] = useState("");
  const [secondCoinBalance, setSecondCoinBalance] = useState(0);
  const [firstCoinBalance, setFirstCoinBalance] = useState(0);
  const [limit, setLimit] = useState(true);
  const [orderMethod, setOrderMethod] = useState("limit");
  const [market, setMarket] = useState(false);
  const [stopLimit, setStopLimit] = useState(false);
  const [stopPriceBuy, setStopPriceBuy] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [currentPrice, setCurrentPrice] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [type, setType] = useState("number");
  const [order_id, setOrder_id] = useState("");
  const [buy_commission, setBuy_commission] = useState("");
  const [bPBtn, setBPBtn] = useState(0);
  const [feeAmyt, setFeeAmyt] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [sell_order_total, setSell_order_total] = useState("");
  const [sellFeeAmyt, setSellFeeAmyt] = useState("");
  const [disabledBuuButtn, setDisabledBuuButtn] = useState(false);
  const [isOrderEdit, setIsOrderEdit] = useState(false);
  const [isFeeEnable, setIsFeeEnable] = useState("0");
  const [isFeeApply, setIsFeeApply] = useState("N");
  const [sell_commission, setSell_commission] = useState("");
  const [firstCoinId, setFirstCoinId] = useState("");
  const [secondCoinId, setSecondCoinId] = useState("");
  const [buy_order_total_write, setBuy_order_total_write] = useState("");
  const [stopPriceSell, setStopPriceSell] = useState("");
  const [sell_order_id, setSell_order_id] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [sPBtn, setSPBtn] = useState(0);
  const [disabledSellButtn, setDisabledSellButtn] = useState(false);
  const [activeSellOrder, setActiveSellOrder] = useState(false);
  const [activeSellOrderData, setActiveSellOrderData] = useState([]);
  const [activeBuyOrder, setActiveBuyOrder] = useState(false);
  const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
  const [marketTrade, setMarketTrade] = useState(false);
  const [marketTradeData, setMarketTradeData] = useState([]);
  const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
  const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
  const [updateAndDeleteButton, setUpdateAndDeleteButton] = useState(false);
  const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
  const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
  const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
  const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
  const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
  const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
  const [InExtype, setInExtype] = useState("exclude");
  const [refresh, setRefresh] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [isEditModelOpenSell, setIsEditModelOpenSell] = useState(false);
  const [themeRefresh, setThemeRefresh] = useState(false);
  const [fav_first_coin, setfav_first_coin] = useState("");
  const [fav_second_coin, setfav_second_coin] = useState("");
  const [buyPercentage, setBuyPercentage] = useState("");
  const [portfolioData, setPortfolioData] = useState("");
  const [livPrice, setLivPrice] = useState("0:00");
  const [onPriceChange, setOnPriceChange] = useState(true);
  const [onChangeChange, setOnChangeChange] = useState(true);
  const [binanceTicker, setBinanceTicker] = useState([]);
  const [binanceTrades, setBinanceTrades] = useState([]);
  const [binanceDepth, setBinanceDepth] = useState([]);
  const [tabSelect, setTabSelect] = useState("Markets");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [popUpModal, setPopModalOpen] = useState(false);
  const [popUpModalData, setPopModalData] = useState([]);

  const sortCoin = (type) => {
    if (type === "price") {
      setOnPriceChange(!onPriceChange);
    }
    if (type === "change") {
      setOnChangeChange(!onChangeChange);
    }
  };
  const streams = ["@ticker", "@depth20", "@trade"];

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  const closeImage = (val, num) => {
    val.view = false;
    // console.log("close");
    let i = 0;
    popUpModalData.map((val) => {
      if (!val.view) {
        i += 1;
      }
    });
    // console.log("close ",i," ",popUpModalData.length);
    if (i == popUpModalData.length) {
      closePopUpModel();
    }
  };

  const closePopUpModel = () => {
    sessionStorage.setItem("popshow", false);
    setPopModalOpen(false);
  };

  const activeBuyOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.bids) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };

        const maxQuantity = binanceDepth.bids[0][1];
        const tableRows = binanceDepth.bids
          .slice(0, 10)
          .map((activeBuyOrderSingle, index) => {
            const tradePrice = parseFloat(activeBuyOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarWidth = calculateProgressBarWidth(
              activeBuyOrderSingle[1],
              maxQuantity
            );

            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding green crypt-up">
                  <span
                    className="tr_prograss2"
                    style={{ width: `${progressBarWidth}` }}
                  ></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatNumber(activeBuyOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeBuyOrderSingle &&
                    formatNumber(
                      activeBuyOrderSingle[0] * activeBuyOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeBuyOrder) {
        collectHtml = [];
        activeBuyOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "buy")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding green crypt-up">
                {item.per_price}
              </td>
              <td className="f-left ng-binding ">
                {item.buy_get_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding text-right">
                {(item.per_price * item.buy_get_amount).toFixed(8)}
              </td>
            </tr>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                {t("No Data Found")}
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const closeEditModel = async () => {
    setIsEditModelOpen(false);
    setIsEditModelOpenSell(false);
    setTimeout(() => {
      setDisabledBuuButtn(false);
      setDisabledSellButtn(false);
    }, 1500);
    const res = await cancleBack({}, token);
    const resp = res.data;
    if (resp.status === true) {
      console.log("order removed and reverted");
    }
  };

  const onEditOrder = (e) => {
    if (secondCoinBalance === 0 || secondCoinBalance === null) {
      setInExtype("include");
    }
    const { name, value } = e.target;
    if (name === "editPrice" || name === "editAmount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }
      newValue = parts.join(".");
      // Regular expression for validating the input
      const regex = /^[0-9]{1,8}(\.[0-9]{1,8})?$/;

      if (regex.test(newValue)) {
        // Value is a valid number
        if (value.length < 17) {
          // Check total length (including digits and decimal point)
          if (name === "editPrice") {
            setBuyPrice(newValue);
          } else if (name === "editAmount") {
            setBuyAmount(newValue);
          }
        }
      } else {
        if (name === "editPrice") {
          setBuyPrice("");
        } else if (name === "editAmount") {
          setBuyAmount("");
        }
      }
    }

    setInExtype("exclude");
  };

  const onEditOrderSell = (e) => {
    if (firstCoinBalance === 0 || firstCoinBalance === null) {
      setInExtype("include");
    }
    const { name, value } = e.target;
    if (name === "editPrice" || name === "editAmount") {
      let newValue = value;
      const parts = newValue.split(".");
      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }
      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }
      newValue = parts.join(".");
      // Regular expression for validating the input
      const regex = /^[0-9]{1,8}(\.[0-9]{1,8})?$/;
      if (regex.test(newValue)) {
        // Value is a valid number
        if (newValue.length < 17) {
          // Check total length (including digits and decimal point)
          if (name === "editPrice") {
            setSellPrice(newValue);
          } else if (name === "editAmount") {
            setSellAmount(newValue);
          }
        }
      } else {
        if (name === "editPrice") {
          setSellPrice("");
        } else if (name === "editAmount") {
          setSellAmount("");
        }
      }
    }
  };

  useEffect(() => {
    cryptoDataByTab();
    if (token) {
      generatePayIdFun();
    }
  }, [token]);

  const generatePayIdFun = async () => {
    await generatePayId({}, token);
  };

  const cryptoDataByTab = async () => {
    const res = await getCryptoDataByTab();
    setExchange_tab(res);
  };

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      getOpenPairfun();
    }
    startDataLoad();
    favourite();
  }, [params.firstCoin, params.secondCoin]);

  const getOpenPairfun = async () => {
    const res = await getOpenPair();
    if (res.status) {
      const pairName = `/${res.data[0].first_name}/${res.data[0].second_name}`;
      window.location.href = `/exchange${pairName}`;
    }
  };

  let favourite = async () => {
    const Url = window.location.pathname.split("/");
    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };
    const result = await checkPair(sendCoinData);
    var apiResp = result.data;
    var apiRespData = apiResp.data;
    if (apiRespData.pairId) {
      let sendCoinPair = {
        pair_id: apiRespData.pairId,
      };
      const respData = await checkPairExchange(sendCoinPair);

      var apiExchangeResp = respData.data;
      if (apiExchangeResp.success === true) {
        // setExchange(apiExchangeResp.data.exchange);
        localStorage.setItem("exType", apiExchangeResp.data.exchange);
      } else {
        localStorage.setItem("exType", "notExchange");
        // setExchange("");
      }
      const favData = await checkFavCoin(sendCoinPair, token);
      var resp = favData.data;
      setIsActive(resp.data);
    }
  };

  const _connectSocketStreams = (streams) => {
    let newArr = [];
    // Close the previous WebSocket connection
    if (binanceWebsocket.current) {
      binanceWebsocket.current.close();
    }
    try {
      streams = streams.join("/");
      // Create a new WebSocket connection
      binanceWebsocket.current = new WebSocket(
        `wss://stream.binance.com:9443/stream?streams=${streams}`
      );
      binanceWebsocket.current.onmessage = async (evt) => {
        const eventData = JSON.parse(evt.data);
        if (eventData.stream.endsWith("@ticker")) {
          //  console.log(eventData.data)
          const obj = eventData.data;
          setLivPrice(obj.c);
          setBinanceTicker(obj);
          setTopBarStatus(true);
        }

        if (eventData.stream.endsWith("@trade")) {
          const mainValue = eventData.data;
          const data = await secondConstructor(mainValue);
          newArr.push(data);
          if (newArr.length > 10) {
            newArr.shift();
          }
          const binanceTrades = { binanceTrades: newArr };
          const trades = binanceTrades.binanceTrades;
          setBinanceTrades(trades);
        }

        if (eventData.stream.endsWith("@depth20")) {
          //console.log(eventData)
          if (eventData.data) {
            setBinanceDepth(eventData.data);
          }
        }
      };

      binanceWebsocket.current.onerror = (evt) => {
        console.error(evt);
      };
    } catch (error) {
      console.log("err2", error);
    }
  };

  const secondConstructor = async (obj) => {
    return {
      cost: obj.p,
      amount: obj.q,
      timestamp: obj.T,
    };
  };

  useEffect(() => {
    const { firstCoin: firstCoinName, secondCoin: secondCoinName } = params;
    const pair = `${firstCoinName}${secondCoinName}`.toLowerCase();

    if (exchange === "binance") {
      _connectSocketStreams(streams.map((i) => `${pair}${i}`));
    }

    const pairData = { firstCoin: firstCoinName, secondCoin: secondCoinName };

    const connectSocket = () => {
      if (!clientRef.current) {
        clientRef.current = io.connect(socketUrl, {
          transports: ["websocket"],
          query: { token },
        });

        clientRef.current.on("connect", () => {
          handleSocketData(
            clientRef.current,
            clientRef.current.id,
            firstCoinName,
            secondCoinName
          );

          const events = [
            "marketDataPair",
            "orderBookData",
            "topBarData",
            "coinPairList",
            "checkPairBinance",
          ];
          events.forEach((event) => clientRef.current.emit(event, pairData));
        });

        clientRef.current.on("disconnect", (reason) => {
          console.log("Socket disconnected:", reason);
          if (reason === "io server disconnect") {
            // The server has disconnected the socket, try to reconnect manually
            clientRef.current.connect();
          } else if (reason === "io client disconnect") {
            // Client disconnected, no action needed
            console.log("Client disconnected.");
          } else {
            // Try reconnecting after a delay if it's not a manual disconnect
            setTimeout(() => {
              if (!window.location.pathname.includes("/exchange")) {
                console.log("Attempting to reconnect...");
                connectSocket();
              }
            }, 1000); // Adjust delay as needed
          }
        });
      }
    };

    connectSocket();

    if (token) {
      subscribeToUserStreams(clientRef.current, firstCoinName, secondCoinName);
    }

    return () => {
      if (
        clientRef.current &&
        !window.location.pathname.includes("/exchange")
      ) {
        console.log("WebSocket connection will be closed on unmount.");
        clientRef.current.disconnect();
      }
    };
  }, [params, exchange, token]);

  const handleSocketData = (socket, id, firstCoin, secondCoin) => {
    socket.off(`marketDataPairApi_${firstCoin}_${secondCoin}`);
    socket.on(`marketDataPairApi_${firstCoin}_${secondCoin}`, (socketData) => {
      const { buyOrderData, sellOrderData } = socketData;
      const activeBuyOrderData = buyOrderData;
      const activeSellOrderData = sellOrderData;
      if (activeBuyOrderData.status) {
        setActiveBuyOrder(activeBuyOrderData.status);
        setActiveBuyOrderData(activeBuyOrderData.data);
      } else {
        setActiveBuyOrder(activeBuyOrderData.status);
        setActiveBuyOrderData([]);
      }
      if (activeSellOrderData.status) {
        setActiveSellOrder(activeSellOrderData.status);
        setActiveSellOrderData(activeSellOrderData.data);
      } else {
        setActiveSellOrder(activeSellOrderData.status);
        setActiveSellOrderData([]);
      }
    });

    socket.off(`orderBookDataApi_${firstCoin}_${secondCoin}`);
    socket.on(`orderBookDataApi_${firstCoin}_${secondCoin}`, (orderData) => {
      const { data, status, lastPrice } = orderData;
      setCurrentPrice(lastPrice);
      setMarketTrade(status);
      setMarketTradeData(data);
    });

    socket.off(`topBarDataApi_${firstCoin}_${secondCoin}`);
    socket.on(`topBarDataApi_${firstCoin}_${secondCoin}`, (topBarData) => {
      const { data, status } = topBarData;
      setTopBarApiData(data);
      setTopBarStatus(status);
    });

    socket.off("coinPairListApi");
    socket.on("coinPairListApi", (coinListData) => {
      const { data, status } = coinListData;
      setCoinListData(data);
      setCoinListStatus(status);
    });
    socket.off(`resultPairBinance_${firstCoin}_${secondCoin}`);
    socket.on(`resultPairBinance_${firstCoin}_${secondCoin}`, (topBarData) => {
      const { data, status } = topBarData;
      if (status === true) {
        setExchange(data.exchange);
      } else {
        setExchange("");
      }
    });
    socket.off("refresh");
    socket.on("refresh", () => {
      const pairData = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };

      clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("orderBookData", pairData); // Emit an event to request order book data for the pair
      clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("coinPairList");
      clientRef.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
      handleSocketData(
        clientRef.current,
        clientRef.current.id,
        firstCoin,
        secondCoin
      );
      setRefresh(!refresh);
      if (token) {
        subscribeToUserStreams(clientRef.current, firstCoin, secondCoin);
      }
    });
  };

  const subscribeToUserStreams = (socket, firstCoin, secondCoin) => {
    const pairData = {
      firstCoin: firstCoin,
      secondCoin: secondCoin,
    };
    socket.emit("userPendingOrders", pairData);
    socket.emit("userCompleteOrders", pairData);
    socket.emit("userBalanceByPair", pairData);
    socket.emit("mySpotBalance", pairData);
    socket.emit("userRealTimePortfolio", pairData);

    handleUserSocketResp(socket);
  };

  const handleUserSocketResp = (socket) => {
    socket.off("userPendingOrdersApi");
    socket.on("userPendingOrdersApi", (pendingOrders) => {
      const { buyOrderData, sellOrderData } = pendingOrders;

      if (buyOrderData.status) {
        setMyActiveBuyOrder(buyOrderData.status);
        setMyActiveBuyOrderData(buyOrderData.data);
      } else {
        setMyActiveBuyOrder(buyOrderData.status);
        setMyActiveBuyOrderData([]);
      }
      if (sellOrderData.status) {
        setMyActiveSellOrder(sellOrderData.status);
        setMyActiveSellOrderData(sellOrderData.data);
      } else {
        setMyActiveSellOrder(sellOrderData.status);
        setMyActiveSellOrderData([]);
      }
    });

    socket.off("userCompleteOrdersApi");
    socket.on("userCompleteOrdersApi", (completedOrders) => {
      const { buyOrderData, sellOrderData } = completedOrders;

      if (buyOrderData.status) {
        setMyCompletedBuyOrder(buyOrderData.status);
        setMyCompletedBuyOrderData(buyOrderData.data);
      } else {
        setMyCompletedBuyOrder(buyOrderData.status);
        setMyCompletedBuyOrderData([]);
      }
      if (sellOrderData.status) {
        setMyCompletedSellOrder(sellOrderData.status);
        setMyCompletedSellOrderData(sellOrderData.data);
      } else {
        setMyCompletedSellOrder(sellOrderData.status);
        setMyCompletedSellOrderData([]);
      }
    });

    socket.off("userBalanceByPairApi");
    socket.on("userBalanceByPairApi", (userBalanceByPairApi) => {
      const { firstCoinBalance, secondCoinBalance } = userBalanceByPairApi;
      setFirstCoinBalance(firstCoinBalance);
      setSecondCoinBalance(secondCoinBalance);
    });

    socket.off("mySpotBalanceApi");
    socket.on("mySpotBalanceApi", (mySpotBalanceApi) => {
      setMySpotBalance(mySpotBalanceApi);
    });

    socket.off("userRealTimePortfolioApi");
    socket.on("userRealTimePortfolioApi", (portfolioBalc) => {
      setPortfolioData(portfolioBalc);
    });
  };

  const startDataLoad = () => {
    setFirstCoin(params.firstCoin);
    setSecondCoin(params.secondCoin);
    setSetUrl(params.secondCoin);
    setSecondCoinBalance(0);
    getPairDetails();
  };

  const getPairDetails = async () => {
    let sendData = {
      firstCoin: params.firstCoin,
      secondCoin: params.secondCoin,
    };
    const respData = await checkPair(sendData);
    const apiResp = respData.data;
    if (apiResp.success === false) {
    }
    const apiRespData = apiResp.data;
    const setBuyPriceGet = formatDecimal(apiRespData.currentPairPrice);
    setFirstCoinId(apiRespData.firstCoinId);
    setSecondCoinId(apiRespData.secondCoinId);
    setBuyPrice(setBuyPriceGet);
    setCurrentPrice(setBuyPriceGet);
    setSellPrice(setBuyPriceGet);
    setBuy_commission(apiRespData.commission);
    setSell_commission(apiRespData.sell_commission);
    setfav_first_coin(apiRespData.firstCoinId);
    setfav_second_coin(apiRespData.secondCoinId);
    getUserDetails();
  };

  const getUserDetails = async () => {
    const res = await userData({}, token);
    const resp = res.data;
    if (resp.key === "logout" && token !== "null" && token !== null) {
      localStorage.clear();
      setTimeout(
        () => (window.location.href = "/login-to-buy-sell-crypto"),
        2000
      );
    }
  };

  const dataOnClick = (firstCoin, secondCoin, isBinance) => {
    console.log(firstCoin);
    console.log(secondCoin);
    if (isBinance === true) {
      if (binanceWebsocket.current) {
        binanceWebsocket.current.close();
      }
      let data = firstCoin + secondCoin;
      let symbol = data.toLowerCase();
      _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
      setExchange("binance");
    } else {
      setExchange("");
      if (binanceWebsocket.current) {
        binanceWebsocket.current.close();
      }
    }

    if (params.firstCoin !== firstCoin) {
      const pairData = {
        firstCoin: firstCoin,
        secondCoin: secondCoin,
      };
      const pairKey = `${params.firstCoin}_${params.secondCoin}`;
      clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
      clientRef.current.emit("unsubscribe", pairKey);
      handleSocketData(
        clientRef.current,
        clientRef.current.id,
        firstCoin,
        secondCoin
      );
      setRefresh(!refresh);
    }
    localStorage.setItem("tab", setActiveTab);
    localStorage.setItem("excoin1", firstCoin);
    localStorage.setItem("excoin2", secondCoin);
  };

  useEffect(() => {
    const activeTab = localStorage.getItem("tab");
    console.log(activeTab);
    if (activeTab) {
      setSetActiveTab(activeTab);
      activeCurrency(activeTab);
    }
  }, []);

  const changeTheme = () => {
    setThemeRefresh(!themeRefresh);
  };

  const favListApi = async (v) => {
    let sendData = {
      firstCoin: fav_first_coin,
      secondCoin: fav_second_coin,
    };
    const respData = await addFavCoin(sendData, token);
    const apiResp = respData.data;
    linkPairClick();
    if (apiResp.status === true) {
      toast.success(t(apiResp.message));
      getPairDetails();
      if (setActiveTab === "fav") {
        activeCurrency("fav");
      }
    } else {
      toast.error(t(apiResp.message));
      getPairDetails();
    }
  };

  const linkPairClick = async () => {
    const Url = window.location.pathname.split("/");
    let sendCoinData = {
      firstCoin: Url[2],
      secondCoin: Url[3],
    };
    const respData = await checkPair(sendCoinData);
    const apiResp = respData.data;
    const apiRespData = apiResp.data;
    if (apiRespData.pairId) {
      let sendCoinPair = {
        pair_id: apiRespData.pairId,
      };
      const apiExchangeResp = await checkPairExchange(sendCoinPair);
      const respData = await checkFavCoin(sendCoinPair, token);
      var resp = respData.data;
      setIsActive(resp.data);
    }
  };

  const activeCurrency = (value) => {
    localStorage.setItem("setUrl", value);
    setSetUrl(value);
    setBuyAmount("");
    setSellAmount("");
    setSell_order_total(0);
    setBuy_order_total(0);
    setSetActiveTab(value);
  };

  const onSearch = (event) => {
    setSearchedValue(event.target.value);
  };

  const EmptySearch = () => {
    setSearchedValue("");
  };

  const coinListHtml = (coinListData) => {
    var listData = [];
    if (coinListStatus === true && coinListData.length > 0) {
      listData = coinListData;
    }
    var filteredItems = listData.filter((item) =>
      item.first_coin_name.includes(searchedValue.toUpperCase())
    );

    return filteredItems.map((item, index) => {
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);
      var showActive =
        firstCoin === item.first_coin_name &&
        secondCoin === item.second_coin_name;

      var lastPrice = parseFloat(item.current_price); // Get the last price from the item object
      var decimalPlaces = lastPrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
      var formattedPrice = lastPrice.toLocaleString("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
      formattedPrice = item.current_price == null ? 0 : formattedPrice;
      if (setActiveTab == "fav" && item.activeStatus == "YES") {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <Link
              to={
                "/exchange/" +
                item.first_coin_name +
                "/" +
                item.second_coin_name
              }
              onClick={() =>
                dataOnClick(
                  item.first_coin_name,
                  item.second_coin_name,
                  item.isBinance
                )
              }
            >
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {item.current_price}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0 ? { color: "red" } : { color: "green" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </Link>
          </tr>
        );
      }
      if (item.second_coin_name == setActiveTab) {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <Link
              to={
                "/exchange/" +
                item.first_coin_name +
                "/" +
                item.second_coin_name
              }
              onClick={() =>
                dataOnClick(
                  item.first_coin_name,
                  item.second_coin_name,
                  item.isBinance
                )
              }
            >
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {isNaN(item.current_price) || item.current_price == null
                  ? "0"
                  : formattedPrice}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0
                      ? { color: "#ff505d" }
                      : { color: "#00bc8b" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </Link>
          </tr>
        );
      }
    });
  };

  const coinListHtml_M = (coinListData) => {
    var listData = [];
    if (coinListStatus === true && coinListData.length > 0) {
      listData = coinListData;
    }
    var filteredItems = listData.filter((item) =>
      item.first_coin_name.includes(searchedValue.toUpperCase())
    );
    //console.log(filteredItems);
    // filteredItems.sort((a, b) => {
    //   const calculatePercentShow = (item) => {
    //     const currentPrice = parseFloat(item.current_price);
    //     const oneDayBeforePrice = parseFloat(item.oneday_before_price);

    //     // Check if oneDayBeforePrice is a valid number
    //     if (isNaN(oneDayBeforePrice) || oneDayBeforePrice === null) {
    //       // Handle the case where oneDayBeforePrice is not a valid number
    //       return 0; // You can adjust this based on your specific requirements
    //     }

    //     const percentChange =
    //       ((currentPrice - oneDayBeforePrice) * 100) / oneDayBeforePrice;
    //     return parseFloat(percentChange.toFixed(2)); // Round to 2 decimal places
    //   };

    //   const percentShowA = calculatePercentShow(a);
    //   const percentShowB = calculatePercentShow(b);

    //   // Sorting based on descending order of percentShow
    //   return onChangeChange  ? percentShowB - percentShowA  : percentShowA - percentShowB;
    // });

    // console.log("filteredItems",filteredItems)

    return filteredItems.map((item, index) => {
      var percentShow =
        ((parseFloat(item.current_price) -
          parseFloat(item.oneday_before_price)) *
          100) /
        item.oneday_before_price;
      percentShow = percentShow.toFixed(2);
      var showActive =
        firstCoin === item.first_coin_name &&
        secondCoin === item.second_coin_name;

      var lastPrice = parseFloat(item.current_price); // Get the last price from the item object
      var decimalPlaces = lastPrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
      var formattedPrice = lastPrice.toLocaleString("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
      formattedPrice = item.current_price == null ? 0 : formattedPrice;
      if (setActiveTab == "fav" && item.activeStatus == "YES") {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <a onClick={() => clickCurrency(item)}>
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {item.current_price}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0 ? { color: "red" } : { color: "green" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </a>
          </tr>
        );
      }
      if (item.second_coin_name == setActiveTab) {
        return (
          <tr
            key={index}
            className={showActive ? "ng-scope active" : "ng-scope"}
          >
            <a onClick={() => clickCurrency(item)}>
              <td className="r-market-pair ng-binding fw600">
                {item.icon ? (
                  <img
                    className="currency_icon"
                    src={baseUrl + "/static/currencyImage/" + item.icon}
                    alt=""
                  />
                ) : (
                  <img
                    className="currency_icon"
                    src="/assets/img/btc.png"
                    alt=""
                  />
                )}
                {item.first_coin_name}
                <small>/{item.second_coin_name}</small>
              </td>
              <td className="r-market-price ng-binding fw600">
                {isNaN(item.current_price) || item.current_price == null
                  ? "0"
                  : formattedPrice}
              </td>
              <td className="r-market-rate ng-scope tr">
                <span
                  className="ng-binding ng-scope green"
                  style={
                    percentShow < 0
                      ? { color: "#ff505d" }
                      : { color: "#00bc8b" }
                  }
                >
                  {isNaN(percentShow) ? "0.00" : percentShow}%
                </span>
              </td>
            </a>
          </tr>
        );
      }
    });
  };

  const removeTrailingZeros = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr).toFixed(8);

    return result;
  };

  const removeTrailingZerosT = (decimalNumber) => {
    // Convert the decimal number to a string
    let numberStr = decimalNumber.toString();

    // Use a regular expression to remove trailing zeros
    numberStr = numberStr.replace(/\.?0*$/, "");

    // Convert the modified string back to a number
    const result = parseFloat(numberStr);

    return result;
  };

  const setStopChanges = (e, data) => {
    e.preventDefault();
    if (data === "stop") {
      setStopLimit(true);
      setDisabledButton(false);
      setMarket(false);
      setLimit(false);
      setBuyAmount("");
      setBuy_order_total("");
      setFeeAmyt("");
      setSellAmount("");
      setSell_order_total("");
      setSellFeeAmyt("");
    }
    if (data === "market") {
      setBuyPrice(currentPrice);
      setSellPrice(currentPrice);
      if (isOrderEdit === true) {
        if (buyAmount > 0) {
          if (
            buy_commission !== null &&
            isFeeApply !== "Y" &&
            isFeeEnable !== "1"
          ) {
            var commission = (buyAmount * buy_commission) / 100;
            var buy_value = parseFloat(buyAmount) + parseFloat(commission);
          } else {
            buy_value = buyAmount;
          }
          const buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(currentPrice);
          var price = currentPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(currentPrice);
          var sellPrice = currentPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(true);
      setStopLimit(false);
      setMarket(true);
      setLimit(false);
    }
    if (data === "limit") {
      if (isOrderEdit === true) {
        if (
          buy_commission !== null &&
          isFeeApply !== "Y" &&
          isFeeEnable !== "1"
        ) {
          commission = (buyAmount * buy_commission) / 100;
          buy_value = parseFloat(buyAmount) + parseFloat(commission);
        } else {
          buy_value = buyAmount;
        }
        if (buyAmount > 0) {
          let buyOrderTotalWithFee =
            parseFloat(buy_value) * parseFloat(buyPrice);
          price = buyPrice * buyAmount;
          setBuy_order_total(price);
          setFeeAmyt(buyOrderTotalWithFee);
        }
        if (sellAmount > 0) {
          let commission =
            sellAmount *
            (100 /
              (100 +
                (isFeeApply !== "Y" && isFeeEnable !== "1"
                  ? sell_commission
                  : "")));
          let sell_value = commission;
          let sellOrderTotalWithFee =
            parseFloat(sell_value) * parseFloat(sellPrice);
          sellPrice = sellPrice * sellAmount;
          setSell_order_total(sellPrice);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      } else {
        setBuyAmount("");
        setBuy_order_total("");
        setSellAmount("");
        setSell_order_total("");
        setFeeAmyt("");
        setSellFeeAmyt("");
      }
      setDisabledButton(false);
      setStopLimit(false);
      setLimit(true);
      setMarket(false);
    }
  };

  const handleBuySubmit = async (event) => {
    event.preventDefault();
    if (buyPrice < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }
    if (buyAmount < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }

    if (buyPrice <= 0) {
      toast.error(t("Please enter valid price"));
      return false;
    }

    var price = "";
    if (limit === true && market === false) {
      price = buyPrice;
    } else if (market === true && limit === false) {
      price = currentPrice;
    }
    if (secondCoinBalance == 0 || secondCoinBalance <= 0) {
      toast.error(t("Insufficient balance to execute this order"));
      return false;
    }
    let submitData;
    let prices = parseFloat(price);
    let perPrice = prices.toFixed(8);
    if (InExtype === "include") {
      submitData = {
        orderType: "buy",
        per_price: formatDecimal(perPrice),
        volume: formatDecimal(buyAmount),
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
        percentAmt: buyPercentage,
      };
    } else {
      submitData = {
        orderType: "buy",
        per_price: formatDecimal(perPrice),
        volume: formatDecimal(buyAmount),
        firstCoinId: firstCoinId,
        secondCoinId: secondCoinId,
        orderId: order_id,
        feeAmyt: feeAmyt,
        orderMethod: orderMethod,
        type: InExtype,
        buy_commission: buy_commission,
      };
    }
    setDisabledBuuButtn(true);
    let endPoint;
    if (orderMethod == "limit") {
      endPoint = "/frontapi/create_order";
    } else if (orderMethod === "market") {
      endPoint = "/frontapi/create_order_market";
    }
    setTimeout(() => setDisabledBuuButtn(false), 500);
    const resp = await orderCreate(endPoint, submitData, token);
    const apiResp = resp.data;
    sendMessageSocket();
    // subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
    if (apiResp.success === false) {
      toast.error(t(apiResp.message));
      //  setBuyAmount(0);
      //  setBuy_order_total(0);
      setOrder_id("");
      setFeeAmyt(0);
      setBPBtn(0);
      if (apiResp.data == 1) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      closeEditModel();
    } else {
      if (apiResp.message === "Failed to authenticate token.") {
        setTimeout(
          () => (window.location.href = "/login-to-buy-sell-crypto"),
          2000
        );
      } else {
        setIsEditModelOpen(false);
        toast.success(t(apiResp.message));
        setBuyAmount("");
        setBuy_order_total("");
        setOrder_id("");
        setFeeAmyt(0);
        setBPBtn(0);
      }
    }
  };

  const sendMessageSocket = () => {
    const firstCoinName = params.firstCoin;
    const secondCoinName = params.secondCoin;
    const pairData = { firstCoin: firstCoinName, secondCoin: secondCoinName };
    clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
    clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
    clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
    clientRef.current.emit("coinPairList");
    handleSocketData(
      clientRef.current,
      clientRef.current.id,
      firstCoinName,
      secondCoinName
    );
    if (token) {
      subscribeToUserStreams(
        clientRef.current,
        params.firstCoin,
        params.secondCoin
      );
    }
  };

  const handleBuyChange = (event) => {
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var buyOrderTotal = "";
    if (name === "buy_order_total" && buyPrice > 0) {
      if (buy_commission !== null) {
        var commission = (value * buy_commission) / 100;
        var buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    } else {
      if (buy_commission !== null) {
        commission = (value * buy_commission) / 100;
        buy_value = parseFloat(value) + parseFloat(commission);
      } else {
        buy_value = value;
      }
    }
    if (buyAmount == 0) {
      buyOrderTotal = (
        parseFloat(buy_value) * parseFloat(buy_order_total_write)
      ).toFixed(8);
      setBuy_order_total(buyOrderTotal);
    }
    if (name === "buyPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 9) {
          setBuyPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setBuyPrice(value);
        }
      }
    }
    if (name === "buyPrice" && buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyAmount);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyPrice(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          setBuyPrice(value);
          buyOrderTotal = (parseFloat(value) * parseFloat(buyAmount)).toFixed(
            8
          );
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
        }
      }
    } else if (name === "buyAmount" && buyPrice > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(buyPrice);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setBuyAmount(formattedValue);
            buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(
              8
            );
            setBuy_order_total(buyOrderTotal);
            setFeeAmyt(buyOrderTotalWithFee);
          }
        }
      } else {
        if (value.includes(".") && value.toString().split(".")[1].length <= 8) {
          buyOrderTotal = (parseFloat(value) * parseFloat(buyPrice)).toFixed(8);
          setBuy_order_total(buyOrderTotal);
          setFeeAmyt(buyOrderTotalWithFee);
          setBuyAmount(value);
        }
      }
    } else if (name === "buy_order_total" && buyPrice > 0) {
      let buyOrderTotal = (parseFloat(value) / parseFloat(buyPrice)).toFixed(8);
      let buyOrderTotalWithFee = parseFloat(buy_value);
      setBuy_order_total_write(buyOrderTotal);
      setBuyAmount(buyOrderTotal);
      setFeeAmyt(buyOrderTotalWithFee);
    }
  };

  const handleSellSubmit = async (event) => {
    event.preventDefault();
    if (sellPrice < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }

    if (sellAmount < 0) {
      toast.error(t("Please enter valid amount"));
      return false;
    }

    if (sellPrice <= 0) {
      toast.error(t("Please enter valid price"));
      return false;
    }

    var price = "";
    if (limit === true) {
      price = sellPrice;
    }
    if (market === true) {
      price = currentPrice;
    }
    if (firstCoinBalance == 0 || firstCoinBalance <= 0) {
      toast.error(t("Insufficient balance to execute this order"));
      return false;
    }
    let submitData = {
      orderType: "sell",
      per_price: formatDecimal(price),
      volume: formatDecimal(sellAmount),
      firstCoinId: firstCoinId,
      secondCoinId: secondCoinId,
      sellOrderId: sell_order_id,
      sellFeeAmyt: sellFeeAmyt,
      orderMethod: orderMethod,
      buy_commission: sell_commission,
    };
    setDisabledSellButtn(true);
    const endPoint = "/frontapi/create_order";
    const resp = await orderCreate(endPoint, submitData, token);
    const apiResp = resp.data;
    sendMessageSocket();
    // subscribeToUserStreams(token, params.firstCoin, params.secondCoin);
    if (apiResp.success === false) {
      closeEditModel();
      toast.error(t(apiResp.message));
      //setBuyAmount(0);
      setBuy_order_total(0);
      setBuy_order_total_write(0);
      setOrder_id("");
      setSell_order_id("");
      setSellFeeAmyt(0);
      setSPBtn(0);
      if (apiResp.data == 1) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      setDisabledSellButtn(false);
    } else {
      setIsEditModelOpenSell(false);
      toast.success(t(apiResp.message));
        setSellAmount("");
      setSell_order_total("");
      setOrder_id("");
      setSell_order_id("");
      setSellFeeAmyt("");
      setSPBtn(0);
      setDisabledSellButtn(false);
    }
  };

  const handleSellChange = (event) => {
    $("input[type=number]").on("mousewheel", function (e) {
      $(e.target).blur();
    });
    let { name, value } = event.target;
    setInExtype("exclude");
    var sellOrderTotal = "";
    if (name === "sell_order_total" && sellPrice > 0) {
      let commission = value * (100 / (100 + sell_commission));
      var sell_value = parseFloat(commission);
    } else {
      let commission = value * (100 / (100 + sell_commission));
      sell_value = commission;
    }
    if (name === "sellPrice") {
      var number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length < 9) {
          setSellPrice(value);
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          setSellPrice(value);
        }
      }
    }
    if (name === "sellPrice" && sellAmount > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellAmount);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellPrice(formattedValue);
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellAmount)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellAmount)).toFixed(
            8
          );
          setSellPrice(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sellAmount" && sellPrice > 0) {
      let sellOrderTotalWithFee =
        parseFloat(sell_value) * parseFloat(sellPrice);
      number = Number(value);
      if (number === Math.floor(number)) {
        if (value.length <= 10) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );

          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        } else {
          const formattedValue = value.replace(/(\.0*|(?<=\.\d)0*)$/, "");

          // Apply length validation
          if (formattedValue.length <= 9) {
            setSellAmount(formattedValue.toFixed(6));
            sellOrderTotal = (
              parseFloat(value) * parseFloat(sellPrice)
            ).toFixed(8);
            setSell_order_total(sellOrderTotal);
            setSellFeeAmyt(sellOrderTotalWithFee);
          }
        }
      } else {
        if (value.toString().split(".")[1].length < 9) {
          sellOrderTotal = (parseFloat(value) * parseFloat(sellPrice)).toFixed(
            8
          );
          setSellAmount(value);
          setSell_order_total(sellOrderTotal);
          setSellFeeAmyt(sellOrderTotalWithFee);
        }
      }
    } else if (name === "sell_order_total" && sellPrice > 0) {
      let sellOrderTotalWithFee = parseFloat(sell_value);
      let sellOrderTotal = (parseFloat(value) / parseFloat(sellPrice)).toFixed(
        8
      );
      setSellAmount(sellOrderTotal);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
  };

  const handleSellPercent = (percentAmt) => {
    var userBalance = firstCoinBalance;
    var percentAmts = (percentAmt * userBalance) / 100;
    percentAmts = percentAmts.toFixed(8);
    let finalAmt = parseFloat(percentAmts) * parseFloat(sellPrice);
    let Amount = percentAmts * (100 / (100 + sell_commission));
    let subTotal = Number(Amount) * Number(sellPrice);
    setSellFeeAmyt(subTotal.toFixed(8));
    setSell_order_total(finalAmt.toFixed(8));
    setSellAmount(parseFloat(percentAmts).toFixed(8));
    setSPBtn(percentAmt);
  };

  const handlePriceChangeChange = (value, side) => {
    if (buy_commission !== null && isFeeApply !== "Y" && isFeeEnable !== "1") {
      var commission = (buyAmount * buy_commission) / 100;
      var buy_value = parseFloat(buyAmount) + parseFloat(commission);
    } else {
      buy_value = buyAmount;
    }
    if (buyAmount > 0) {
      let buyOrderTotalWithFee = parseFloat(buy_value) * parseFloat(value);
      var price = value * buyAmount;
      setBuy_order_total(price);
      setFeeAmyt(buyOrderTotalWithFee);
    }
    if (sellAmount > 0) {
      let commission =
        sellAmount *
        (100 /
          (100 +
            (isFeeApply !== "Y" && isFeeEnable !== "1"
              ? sell_commission
              : "")));
      let sell_value = commission;
      let sellOrderTotalWithFee = parseFloat(sell_value) * parseFloat(value);
      var sellPriceNew = value * sellAmount;
      setSell_order_total(sellPriceNew);
      setSellFeeAmyt(sellOrderTotalWithFee);
    }
    setBuyPrice(value);
    setSellPrice(value);
  };

  const handlePriceChangeChangeBinance = (value) => {
    setBuyPrice(parseFloat(value.replace(/,/g, "")));
    setSellPrice(parseFloat(value.replace(/,/g, "")));
  };

  const updateBuyOrder = async (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error(t("Invalid Order"));
      return false;
    }

    if (orderType === undefined) {
      toast.error(t("Invalid Order"));
      return false;
    }

    if (orderType !== "buy" && orderType !== "sell") {
      toast.error(t("Invalid Order Type"));
      return false;
    }

    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };
    setUpdateAndDeleteButton(true);
    const resp = await getOrder(submitData, token);
    var apiResp = resp.data;
    setTimeout(() => {
      setUpdateAndDeleteButton(false);
    }, 2000);

    if (secondCoinBalance == 0 || secondCoinBalance == null) {
      setInExtype(t("include"));
    }

    if (apiResp.success === false) {
      toast.error(t(apiResp.message));
    } else {
      var orderData = apiResp.data[0];
      setIsEditModelOpen(true);
      setDisabledBuuButtn(true);
      setBuyPrice(parseFloat(orderData.per_price));
      setBuyAmount(orderData.total_buy_get_amount);
      setOrder_id(orderData.id);
      setFirstCoinNew(orderData.name1);
      setFeeAmyt(orderData.net_amount);
      setInExtype(orderData.fees_type);
      if (orderData.total_buy_get_amount == 1) {
      } else {
        setBuyAmount(orderData.total_buy_get_amount);
      }
      toast.success(t(apiResp.message));
    }
  };

  const deleteOrder = async (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error(t("Invalid Order"));
      return false;
    }
    if (orderType === undefined) {
      toast.error(t("Invalid Order"));
      return false;
    }
    if (orderType !== "buy" && orderType !== "sell") {
      toast.error(t("Invalid Order Type"));
      return false;
    }
    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };
    setUpdateAndDeleteButton(true);
    setTimeout(() => {
      setUpdateAndDeleteButton(false);
    }, 1000);
    const resp = await deleteOrderFun(submitData, token);
    sendMessageSocket();
    var apiResp = resp.data;
    if (apiResp.success === false) {
      toast.error(t(apiResp.message));
    } else {
      toast.success(t(apiResp.message));
    }
  };

  const updateSellOrder = async (orderId, orderType) => {
    if (orderId === undefined || orderId < 0) {
      toast.error(t("Invalid Order"));
      return false;
    }
    if (orderType === undefined) {
      toast.error(t("Invalid Order"));
      return false;
    }
    if (orderType !== "buy" && orderType !== "sell") {
      toast.error(t("Invalid Order Type"));
      return false;
    }
    let submitData = {
      order_type: orderType,
      order_id: orderId,
    };
    const resp = await getOrder(submitData, token);
    var apiResp = resp.data;
    if (firstCoinBalance == 0 || firstCoinBalance == null) {
      setInExtype(t("include"));
    }
    if (apiResp.success === false) {
      toast.error(t(apiResp.message));
    } else {
      var orderData = apiResp.data[0];
      setIsEditModelOpenSell(true);
      setDisabledSellButtn(true);
      setSellPrice(orderData.per_price);
      setSellAmount(orderData.total_sell_spend_amount);
      setSell_order_id(orderData.id);
      setFirstCoinNew(orderData.name1);
      setSellFeeAmyt(orderData.net_amount);
      if (orderData.total_sell_spend_amount == 1) {
        setSellAmount(orderData.total_sell_spend_amount);
      }
      toast.success(t(apiResp.message));
    }
  };

  var tempData = binanceTicker;
  if (exchange === "binance") {
    var titleData =
      new BigNumber(tempData.c).toFormat(null, 1) +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      tempData.s +
      "\xa0\xa0" +
      "|" +
      "\xa0\xa0" +
      `${ProductName}  Exchange`;
    if (titleData == `NaN  |  undefined  |  ${ProductName}  Exchange`) {
      titleData = "Crypto Buying Selling In India | Bitcoin Exchange Platform";
    }
  } else {
    titleData =
      currentPrice === null || currentPrice == ""
        ? "0" +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`
        : currentPrice > 1
        ? currentPrice.toLocaleString() +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`
        : currentPrice +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          firstCoin +
          secondCoin +
          "\xa0\xa0" +
          "|" +
          "\xa0\xa0" +
          `${ProductName} Exchange`;
  }

  const meta = {
    title: titleData.toString(),
    description: `${ProductName}  Exchange Live Is A Crypto Trading Platform That Exchanges Cryptocurrencies Like Bitcoin, Dogecoin, TRX, XRP, MRC etc. Sign Up For A New Account.`,
    canonical: `https://${ProductName} Exchange.com/high-limit-crypto-exchange`,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "Bitcoin Exchange, Crypto Buying Selling, Crypto Platform, Cryptocurrencies App ",
      },
    },
  };

  // const showRef = (val, ref) => {
  //   console.log(ref);
  //   console.log(val);
  //   if (val != "Markets") {
  //     let topRef = ref.offsetTop - 60;
  //     if (val == "buy_sell") {
  //       topRef = ref.offsetTop + 200;
  //     }
  //     if (val == "charts") {
  //       topRef = ref.offsetTop + 200;
  //     }
  //     if (val == "Home") {
  //       topRef = ref.offsetTop - 350;
  //     }
  //     window.scrollTo({
  //       top: topRef,
  //       left: 0,
  //       behavior: "smooth",
  //     });
  //   }
  //   setTabSelect(val);
  // };

  const showRef = (val, ref) => {
    let topRef = 60;
    if (val != "Markets") {
      if (ref) {
        topRef = ref.offsetTop - 60;
      }
      if (val == "buy_sell") {
        topRef = topRef + 200;
      }
      if (val == "charts") {
        topRef = topRef + 200;
      }
      if (val == "Home") {
        topRef = topRef - 350;
      }
      if (val == "trades") {
        //1242
        let diff = 1242 - topRef;
        topRef = topRef + diff;
      }
    } else {
      topRef = topRef - 50;
    }
    window.scrollTo({
      top: topRef,
      left: 0,
      behavior: "smooth",
    });

    setTabSelect(val);
  };

  const clickCurrency = (value) => {
    history.push(
      "/exchange/" + value.first_coin_name + "/" + value.second_coin_name + ""
    );
    setTabSelect("Home");
  };

  function handleWindowSizeChange() {
    // console.log(window.innerWidth);
    setScreenWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const activeSellOrderHtml = () => {
    var collectHtml = [];
    if (exchange === "binance") {
      if (binanceDepth && binanceDepth.asks) {
        const calculateProgressBarWidth = (quantity, maxQuantity) => {
          const percentage = (quantity / maxQuantity) * 100;
          return percentage > 100 ? "100%" : `${percentage.toFixed(2)}%`;
        };
        const sortedAsks = binanceDepth.asks.sort((a, b) => b[0] - a[0]);
        let asksToShow = sortedAsks.slice(10);
        const maxQuantity = binanceDepth.asks[0][1];
        const tableRows = asksToShow
          .slice(0, 10)
          .map((activeSellOrderSingle, index) => {
            const tradePrice = parseFloat(activeSellOrderSingle[0]);
            const decimalPlaces = tradePrice < 1 ? 8 : 3;
            const formattedPrice = tradePrice.toLocaleString("en-US", {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
            });
            const progressBarStyle = {
              width: calculateProgressBarWidth(
                activeSellOrderSingle[1],
                maxQuantity
              ),
            };
            return (
              <tr
                className="ng-scope tr_prograss_depth"
                key={index}
                onClick={() => handlePriceChangeChangeBinance(formattedPrice)}
                style={{ cursor: "pointer" }}
              >
                <td className="f-left ng-binding magenta crypt-down">
                  <span className="tr_prograss" style={progressBarStyle}></span>
                  {formattedPrice}
                </td>
                <td className="f-left ng-binding ">
                  {formatNumber(activeSellOrderSingle[1])}
                </td>
                <td className="f-left ng-binding text-right">
                  {activeSellOrderSingle &&
                    formatNumber(
                      activeSellOrderSingle[0] * activeSellOrderSingle[1]
                    )}
                </td>
              </tr>
            );
          });

        return <tbody>{tableRows}</tbody>;
      }
    } else {
      if (activeSellOrder) {
        collectHtml = [];
        activeSellOrderData.map((item, i) => {
          collectHtml.push(
            <tr
              className="ng-scope"
              key={i}
              onClick={() => handlePriceChangeChange(item.per_price, "sell")}
              style={{ cursor: "pointer" }}
            >
              <td className="f-left ng-binding magenta crypt-down">
                {item.per_price}
              </td>
              <td className="f-left ng-binding ">
                {parseFloat(item.sell_spend_amount).toFixed(8)}
              </td>
              <td className="f-left ng-binding text-right">
                {(item.per_price * item.sell_spend_amount).toFixed(8)}
              </td>
            </tr>
            // </a>
          );
        });
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                {t("No Data Found")}
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  const formatNumber = (number) => {
    return number
      .toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 6,
      })
      .replace(/\.?0+$/, "");
  };

  const sideLastHtml = () => {
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        const tradePrice = parseFloat(livPrice); // Get the last price from the item object
        const decimalPlaces = tradePrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
        const formattedPrice = tradePrice.toLocaleString("en-US", {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        });
        return (
          <ul>
            <li>
              <strong className="ng-binding mm">{formattedPrice}</strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      } else {
        var topBarData = topBarApiData;
        var collectHtml = (
          <ul>
            <li>
              <strong className="ng-binding mm">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };

  const showSpotBalance = () => {
    if (mySpotBalance.length > 0) {
      const html = [];
      mySpotBalance.length > 0 &&
        mySpotBalance.map(function (value, i) {
          if (
            value.spot[0].coin_amount === null ||
            value.spot[0].coin_amount === "null"
          ) {
            var spotBalace = 0;
          } else {
            spotBalace = value.spot[0].coin_amount;
          }
          if (spotBalace > 0) {
            html.push(
              <tr key={i}>
                <td>{value.name}</td>
                <td>{spotBalace}</td>
              </tr>
            );
          }
        });
      return <tbody className="main">{html}</tbody>;
    }
  };

  const myActiveBuyOrderHtml = () => {
    if (myActiveBuyOrder) {
      var collectHtml = [];

      myActiveBuyOrderData.forEach((e, i) => {
        const parsedDate = Moment(e.created_at);
        var getTime = parsedDate.format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair} abx</td>
            <td className="f-left ng-binding green">
              {formatDecimal(e.per_price)}
            </td>
            <td className="f-left ng-binding">
              {formatDecimal(e.buy_get_amount)}
            </td>
            <td className="f-left ng-binding">
              {(e.per_price * e.buy_get_amount).toFixed(8)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  disabled={updateAndDeleteButton}
                  onClick={() => updateBuyOrder(e.buy_id, "buy")}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                disabled={updateAndDeleteButton}
                onClick={() => deleteOrder(e.buy_id, "buy")}
              >
                <i className="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        );
      });

      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedBuyOrderHtml = () => {
    if (myCompletedBuyOrder) {
      var collectHtml = [];
      for (var i = 0; i < 10; i++) {
        var myCompletedBuyOrderSingle = myCompletedBuyOrderData[i];
        if (myCompletedBuyOrderSingle) {
          var getTime = Moment(myCompletedBuyOrderSingle.created_at).format(
            "lll"
          );
          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedBuyOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {removeTrailingZerosOrder(myCompletedBuyOrderSingle.per_price)}
              </td>
              <td className="f-left ng-binding">
                {removeTrailingZerosOrder(
                  myCompletedBuyOrderSingle.total_buy_get_amount
                )}
              </td>
              <td className="f-left ng-binding">
                {removeTrailingZerosOrder(
                  myCompletedBuyOrderSingle.per_price *
                    myCompletedBuyOrderSingle.total_buy_get_amount
                )}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const removeTrailingZerosOrder = (number) => {
    const stringNumber = number.toFixed(8);
    const formattedNumber = parseFloat(stringNumber).toString();
    return formattedNumber;
  };

  const myActiveSellOrderHtml = () => {
    if (myActiveSellOrder) {
      var collectHtml = [];
      myActiveSellOrderData.forEach((e, i) => {
        var getTime = Moment(e.created_at).format("lll");
        collectHtml.push(
          <tr className="ng-scope" key={i}>
            <td className="f-left ng-binding green">{e.coin_pair}</td>
            <td className="f-left ng-binding green">
              {e.per_price.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {e.sell_spend_amount.toFixed(8)}
            </td>
            <td className="f-left ng-binding">
              {(e.per_price * e.sell_spend_amount).toFixed(4)}
            </td>
            <td className="f-left ng-binding green">{getTime}</td>
            <td className="f-left ng-binding">
              {!e.binance_order_id && (
                <button
                  className="btn  mr-2"
                  onClick={() => updateSellOrder(e.sell_id, "sell")}
                  disabled={updateAndDeleteButton}
                >
                  <i className="fa fa-edit"></i>
                </button>
              )}
              <button
                className="btn "
                onClick={() => deleteOrder(e.sell_id, "sell")}
                disabled={updateAndDeleteButton}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        );
      });
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const myCompletedSellOrderHtml = () => {
    if (myCompletedSellOrder) {
      var collectHtml = [];
      for (var i = 0; i < 10; i++) {
        var myCompletedSellOrderSingle = myCompletedSellOrderData[i];
        if (myCompletedSellOrderSingle) {
          var getTime = Moment(myCompletedSellOrderSingle.created_at).format(
            "lll"
          );

          getTime = getTime.replace("T", " ");
          getTime = getTime.replace(".000Z", "");
          collectHtml.push(
            <tr className="ng-scope">
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.coin_pair}
              </td>
              <td className="f-left ng-binding green">
                {myCompletedSellOrderSingle.per_price.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {myCompletedSellOrderSingle.total_sell_spend_amount.toFixed(8)}
              </td>
              <td className="f-left ng-binding">
                {(
                  myCompletedSellOrderSingle.per_price *
                  myCompletedSellOrderSingle.total_sell_spend_amount
                ).toFixed(8)}
              </td>
              <td className="f-left ng-binding green">{getTime}</td>
            </tr>
          );
        }
      }
      return <tbody>{collectHtml}</tbody>;
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              {t("No Data Found")}
            </td>
          </tr>
        </tbody>
      );
    }
  };

  const marketTradeHtml = () => {
    if (exchange === "binance") {
      if (binanceTrades) {
        return (
          <tbody>
            <MarketTrade
              trades={binanceTrades}
              record={[]}
              currentPair={params.firstCoin + "/" + params.secondCoin}
            />
          </tbody>
        );
      }
    } else {
      if (marketTrade) {
        var collectHtml = [];
        for (var i = 0; i < marketTradeData.length; i++) {
          var singleData = marketTradeData[i];
          var setColor = singleData.extype === "buy" ? "green" : "magenta";
          var getTime = Moment(singleData.created_at).format("lll");
          collectHtml.push(
            <tr className="ng-scope" key={singleData.id}>
              <td className={"f-left ng-binding " + setColor}>
                {singleData.get_per_price}
              </td>
              <td className="f-left ng-binding">{singleData.get_amount}</td>
              <td className="f-left ng-binding">
                {singleData.firstCoin + "/" + singleData.secondCoin}
              </td>
              <td className="f-left ng-binding">{getTime}</td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No data avaliable
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };

  return (
    <div>
      <DocumentMeta {...meta}>
        <ExchangeKycHeader
          graphColor={changeTheme}
          portfolioData={portfolioData}
        />{" "}
        <div className="bghwhite">
          <div className="dashbord_manbox dashbord_manbox_das exchange-main-outer">
            <div className="container-fluid">
              <div className="dashbord_in1">
                <div className="dashbord_in dashbord_in_top fs12">
                  {screenWidth > 766 ? (
                    <>
                      <div className="kline-para kline-para-basic d-flex align-items-center">
                        <div className="box-kline-para-basic-left">
                          <span className="productSymbol has-info ng-scope">
                            <strong className="ng-binding">
                              {token && (
                                <svg
                                  viewBox="0 0 38 35"
                                  width="20px"
                                  height="20px"
                                  onClick={favListApi}
                                  style={{ cursor: "pointer" }}
                                >
                                  <g id="Home" />
                                  <g id="Print" />
                                  <g id="Mail" />
                                  <g id="Camera" />
                                  <g id="Video" />
                                  <g id="Film" />
                                  <g id="Message" />
                                  <g id="Telephone" />
                                  <g id="User" />
                                  <g id="File" />
                                  <g id="Folder" />
                                  <g id="Map" />
                                  <g id="Download" />
                                  <g id="Upload" />
                                  <g id="Video_Recorder" />
                                  <g id="Schedule" />
                                  <g id="Cart" />
                                  <g id="Setting" />
                                  <g id="Search" />
                                  <g id="Pencils" />
                                  <g id="Group" />
                                  <g id="Record" />
                                  <g id="Headphone" />
                                  <g id="Music_Player" />
                                  <g id="Sound_On" />
                                  <g id="Sound_Off" />
                                  <g id="Lock" />
                                  <g id="Lock_open" />
                                  <g id="Love" />
                                  <g id="Favorite">
                                    <path
                                      d="M30.9,10.6C30.8,10.2,30.4,10,30,10h0h-9l-4.1-8.4C16.7,1.2,16.4,1,16,1v0c0,0,0,0,0,0   c-0.4,0-0.7,0.2-0.9,0.6L11,10H2c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l6.6,7.6L5,29.7c-0.1,0.4,0,0.8,0.3,1   s0.7,0.3,1.1,0.1l9.6-4.6l9.6,4.6C25.7,31,25.8,31,26,31h0h0h0c0.5,0,1-0.4,1-1c0-0.2,0-0.3-0.1-0.5l-2.8-10.3l6.6-7.6   C31,11.4,31.1,10.9,30.9,10.6z"
                                      fill="transparent"
                                      style={
                                        isActive === "N"
                                          ? { stroke: "#e1ae39" }
                                          : {
                                              stroke: "#e1ae39",
                                              fill: "#e1ae39",
                                            }
                                      }
                                    />
                                  </g>
                                  <g id="Film_1_" />
                                  <g id="Music" />
                                  <g id="Puzzle" />
                                  <g id="Turn_Off" />
                                  <g id="Book" />
                                  <g id="Save" />
                                  <g id="Reload" />
                                  <g id="Trash" />
                                  <g id="Tag" />
                                  <g id="Link" />
                                  <g id="Like" />
                                  <g id="Bad" />
                                  <g id="Gallery" />
                                  <g id="Add" />
                                  <g id="Close" />
                                  <g id="Forward" />
                                  <g id="Back" />
                                  <g id="Buy" />
                                  <g id="Mac" />
                                  <g id="Laptop" />
                                </svg>
                              )}
                              {firstCoin}
                            </strong>
                            <font className="ng-binding"> / {secondCoin}</font>
                          </span>
                        </div>
                        <div className="box-kline-para-basic-right">
                          {
                            <TopBar
                              topBarStatus={topBarStatus}
                              binanceTicker={binanceTicker}
                              topBarApiData={topBarApiData}
                              secondCoin={secondCoin}
                              exchange={exchange}
                            />
                          }
                          <div className="cls"></div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 exchange_right order-md-2">
                          <div
                            className="ne_box p-0 position-relative"
                            ref={chartRef}
                          >
                            {/* {exchange == "" ? ( */}
                            <TradingViewChart
                              params={params}
                              graphColor={themeRefresh}
                            />
                            {/* ) : (
                          <div className="binance-chart-container">
                            {openTradingChart(firstCoin + secondCoin)}
                          </div>
                        )} */}

                            {/* <div className="binance-chart-container">
                            {
                              <BinanceChart
                                symbol={params.firstCoin + params.secondCoin}
                              />
                            }
                          </div> */}
                          </div>
                          <div className="m_h_100" ref={buy_sellRef}>
                            <div className="dp_none status-outer">
                              <ul class="nav nav-tabs2 ">
                                <li class="nav-item">
                                  <a
                                    class="nav-link active m-2"
                                    data-toggle="tab"
                                    href="#buymobile"
                                  >
                                    {t("Buy")}{" "}
                                    {firstCoinNew != ""
                                      ? firstCoinNew
                                      : firstCoin}
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    class="nav-link m-2"
                                    data-toggle="tab"
                                    href="#sellmobile"
                                  >
                                    {t("Sell")}{" "}
                                    {firstCoinNew != ""
                                      ? firstCoinNew
                                      : firstCoin}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="row tab-content h306">
                              <div
                                className="col-md-6 tab-pane active"
                                id="buymobile"
                              >
                                <div className="ne_box h_100 ">
                                  {" "}
                                  <BuyBox
                                    firstCoinNew={firstCoinNew}
                                    firstCoin={firstCoin}
                                    secondCoin={secondCoin}
                                    secondCoinBalance={secondCoinBalance}
                                    removeTrailingZeros={removeTrailingZeros}
                                    setStopChanges={setStopChanges}
                                    setOrderMethod={setOrderMethod}
                                    limit={limit}
                                    market={market}
                                    handleBuySubmit={handleBuySubmit}
                                    stopLimit={stopLimit}
                                    stopPriceBuy={stopPriceBuy}
                                    handleBuyChange={handleBuyChange}
                                    orderMethod={orderMethod}
                                    disabledButton={disabledButton}
                                    order_id={order_id}
                                    handleKeyPress={handleKeyPress}
                                    type={type}
                                    currentPrice={currentPrice}
                                    buyPrice={buyPrice}
                                    buyAmount={buyAmount}
                                    bPBtn={bPBtn}
                                    buy_order_total={buy_order_total}
                                    buy_commission={buy_commission}
                                    removeTrailingZerosT={removeTrailingZerosT}
                                    feeAmyt={feeAmyt}
                                    token={token}
                                    disabledBuuButtn={disabledBuuButtn}
                                    setBuyPercentage={setBuyPercentage}
                                    setBuy_order_total={setBuy_order_total}
                                    setBuyAmount={setBuyAmount}
                                    setBPBtn={setBPBtn}
                                    setInExtype={setInExtype}
                                    setFeeAmyt={setFeeAmyt}
                                  />
                                </div>
                              </div>
                              <div
                                className="col-md-6  tab-pane dp_block"
                                id="sellmobile"
                              >
                                <div className="ne_box h_100 ">
                                  {" "}
                                  <SellBox
                                    firstCoinNew={firstCoinNew}
                                    firstCoin={firstCoin}
                                    firstCoinBalance={firstCoinBalance}
                                    removeTrailingZeros={removeTrailingZeros}
                                    setStopChanges={setStopChanges}
                                    setOrderMethod={setOrderMethod}
                                    limit={limit}
                                    market={market}
                                    sell_commission={sell_commission}
                                    removeTrailingZerosT={removeTrailingZerosT}
                                    handleSellSubmit={handleSellSubmit}
                                    stopLimit={stopLimit}
                                    stopPriceSell={stopPriceSell}
                                    handleSellChange={handleSellChange}
                                    sellFeeAmyt={sellFeeAmyt}
                                    secondCoin={secondCoin}
                                    orderMethod={orderMethod}
                                    disabledButton={disabledButton}
                                    handleKeyPress={handleKeyPress}
                                    sell_order_id={sell_order_id}
                                    sellPrice={sellPrice}
                                    handleSellPercent={handleSellPercent}
                                    sPBtn={sPBtn}
                                    currentPrice={currentPrice}
                                    sellAmount={sellAmount}
                                    sell_order_total={sell_order_total}
                                    type={type}
                                    token={token}
                                    disabledSellButtn={disabledSellButtn}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-md-3 order-md-3 padding-right exchange_left"
                          ref={tradesRef}
                        >
                          <div className="order-history-outer ne_box col-non-padding col-min-padding-right br_0">
                            <div className="panel panel-default">
                              <div className="panel-heading tab-box">
                                <ul className="exchange_tab">
                                  {token && (
                                    <li
                                      className={
                                        setUrl === "fav"
                                          ? "active tabQuoteAsset"
                                          : "tabQuoteAsset"
                                      }
                                      onClick={() => activeCurrency("fav")}
                                    >
                                      <i className="fa fa-star"></i>
                                    </li>
                                  )}
                                  {exchange_tab.map((list) => {
                                    return (
                                      <li
                                        key={list.id}
                                        className={
                                          setUrl == list.short_name
                                            ? "active tabQuoteAsset"
                                            : "tabQuoteAsset"
                                        }
                                        onClick={() =>
                                          activeCurrency(list.short_name)
                                        }
                                      >
                                        {list.short_name}
                                      </li>
                                    );
                                  })}
                                </ul>
                                <div className="clearfix"></div>
                              </div>
                              <div className="panel-body p-0">
                                <div className="searchFilter f-cb mo_none">
                                  <div className="search-box search-box-new">
                                    <input
                                      type="text"
                                      id="search-int"
                                      placeholder=""
                                      className="ng-pristine ng-untouched ng-valid ng-empty"
                                      onChange={onSearch}
                                      value={searchedValue}
                                    />
                                    {searchedValue !== "" ? (
                                      <i
                                        onClick={EmptySearch}
                                        className="las la-times-circle"
                                      ></i>
                                    ) : (
                                      <i className="las la-search"></i>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* <div class="dropdown_currancy">
                            <div type="button" class="dropdown-toggle dp_none mt-3" data-toggle="dropdown">
                              Select Coin Pair
                            </div>
                            <div class="dropdown-menu"> */}
                              <table className="table mb-0 currency_table1">
                                <thead>
                                  <tr>
                                    <th
                                      className="f-left r-market-pair ng-binding"
                                      ng-click="sortByKey('baseAsset')"
                                    >
                                      {t("Pair")}
                                    </th>
                                    <th
                                      className="f-left r-market-price ng-binding"
                                      // onClick={() => sortCoin("price")}
                                      // style={{ cursor: "pointer" }}
                                    >
                                      {t("Price")}
                                      <span className="ng-scope">
                                        {/* {onPriceChange === true ? (
                                      <i className="fa fa-caret-down"></i>
                                    ) : (
                                      <i className="fa fa-caret-up"></i>
                                    )} */}
                                      </span>
                                    </th>

                                    <th
                                      className="r-market-rate ng-binding ng-scope tr"
                                      onClick={() => sortCoin("change")}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {t("Change")}
                                      <span className="ng-scope">
                                        {onChangeChange === true ? (
                                          <i className="fa fa-caret-down"></i>
                                        ) : (
                                          <i className="fa fa-caret-up"></i>
                                        )}
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                              </table>
                              <div className="table-overflow">
                                <table className="table table-hover currency_table">
                                  {coinListHtml(coinListData)}
                                </table>
                              </div>
                              {/* </div>
                          </div> */}
                            </div>
                          </div>
                        </div>

                        <MarketDepth
                          exchange={exchange}
                          binanceDepth={binanceDepth}
                          handlePriceChangeChangeBinance={
                            handlePriceChangeChangeBinance
                          }
                          activeSellOrder={activeSellOrder}
                          activeSellOrderData={activeSellOrderData}
                          handlePriceChangeChange={handlePriceChangeChange}
                          topBarStatus={topBarStatus}
                          livPrice={livPrice}
                          topBarApiData={topBarApiData}
                          secondCoin={secondCoin}
                          activeBuyOrder={activeBuyOrder}
                          activeBuyOrderData={activeBuyOrderData}
                          // marketRef={marketRef}
                        />
                      </div>
                      <div className="color-background orderform-main">
                        <div className="row">
                          <SpotBalance mySpotBalance={mySpotBalance} />
                          <div className="col-md-5" ref={myordersRef}>
                            <div className="ne_box h_100">
                              <div className="table_hadding flex-wrap ">
                                <h6 className="h6_head">{t("Orders")}</h6>
                                <div className="status-outer ">
                                  <ul className="nav nav-tabs2" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#Buytab"
                                        role="tab"
                                      >
                                        {t("Buy")}
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#Selltab"
                                        role="tab"
                                      >
                                        {t("Sell")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="buy-order-outer tab-content">
                                <BuyOrder
                                  myCompletedBuyOrder={myCompletedBuyOrder}
                                  myCompletedBuyOrderData={
                                    myCompletedBuyOrderData
                                  }
                                  myActiveBuyOrder={myActiveBuyOrder}
                                  myActiveBuyOrderData={myActiveBuyOrderData}
                                  updateAndDeleteButton={updateAndDeleteButton}
                                  updateBuyOrder={updateBuyOrder}
                                  deleteOrder={deleteOrder}
                                />

                                <SellOrder
                                  myActiveSellOrder={myActiveSellOrder}
                                  myActiveSellOrderData={myActiveSellOrderData}
                                  updateSellOrder={updateSellOrder}
                                  updateAndDeleteButton={updateAndDeleteButton}
                                  deleteOrder={deleteOrder}
                                  myCompletedSellOrder={myCompletedSellOrder}
                                  myCompletedSellOrderData={
                                    myCompletedSellOrderData
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <Modal
                            className="modify_order"
                            show={isEditModelOpen}
                            onHide={closeEditModel}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title> Modify Order</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <input
                                type="number"
                                className="form-control  mb-3"
                                name="editPrice"
                                value={buyPrice}
                                onChange={onEditOrder}
                              />
                              <input
                                type="number"
                                className="form-control  mb-3"
                                name="editAmount"
                                value={buyAmount}
                                onChange={onEditOrder}
                              />
                              <button
                                onClick={handleBuySubmit}
                                className="btn w100 my_color_buy"
                              >
                                Update
                              </button>
                            </Modal.Body>
                          </Modal>

                          <Modal
                            className="modify_order"
                            show={isEditModelOpenSell}
                            onHide={closeEditModel}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title> Modify Order</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <input
                                type="number"
                                name="editPrice"
                                className="form-control mb-3"
                                value={sellPrice}
                                onChange={onEditOrderSell}
                              />
                              <input
                                type="number"
                                name="editAmount"
                                className="form-control  mb-3"
                                value={sellAmount}
                                onChange={onEditOrderSell}
                              />
                              <button
                                onClick={handleSellSubmit}
                                className="btn w100 my_color_sell"
                              >
                                Update
                              </button>
                            </Modal.Body>
                          </Modal>

                          <OrderHistory
                            exchange={exchange}
                            marketTradeData={marketTradeData}
                            marketTrade={marketTrade}
                            binanceTrades={binanceTrades}
                            MarketTrade={MarketTrade}
                            params={params}
                          />
                        </div>
                      </div>
                      <div className="copy-right">
                        <p>
                          © 2024 {t("DBIEXC")} {/* {ProductName}*/}.{" "}
                          {t("All Rights Reserved version")}{" "}
                          <small>1.0.8</small>
                        </p>
                      </div>
                    </>
                  ) : (
                    <div style={{ marginBottom: "60px" }}>
                      {tabSelect == "Home" ? (
                        <>
                          <div className="kline-para kline-para-basic d-flex align-items-center">
                            <div className="box-kline-para-basic-left">
                              <span className="productSymbol has-info ng-scope">
                                <strong className="ng-binding">
                                  {token && (
                                    <svg
                                      viewBox="0 0 38 35"
                                      width="20px"
                                      height="20px"
                                      onClick={favListApi}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <g id="Home" />
                                      <g id="Print" />
                                      <g id="Mail" />
                                      <g id="Camera" />
                                      <g id="Video" />
                                      <g id="Film" />
                                      <g id="Message" />
                                      <g id="Telephone" />
                                      <g id="User" />
                                      <g id="File" />
                                      <g id="Folder" />
                                      <g id="Map" />
                                      <g id="Download" />
                                      <g id="Upload" />
                                      <g id="Video_Recorder" />
                                      <g id="Schedule" />
                                      <g id="Cart" />
                                      <g id="Setting" />
                                      <g id="Search" />
                                      <g id="Pencils" />
                                      <g id="Group" />
                                      <g id="Record" />
                                      <g id="Headphone" />
                                      <g id="Music_Player" />
                                      <g id="Sound_On" />
                                      <g id="Sound_Off" />
                                      <g id="Lock" />
                                      <g id="Lock_open" />
                                      <g id="Love" />
                                      <g id="Favorite">
                                        <path
                                          d="M30.9,10.6C30.8,10.2,30.4,10,30,10h0h-9l-4.1-8.4C16.7,1.2,16.4,1,16,1v0c0,0,0,0,0,0   c-0.4,0-0.7,0.2-0.9,0.6L11,10H2c-0.4,0-0.8,0.2-0.9,0.6c-0.2,0.4-0.1,0.8,0.2,1.1l6.6,7.6L5,29.7c-0.1,0.4,0,0.8,0.3,1   s0.7,0.3,1.1,0.1l9.6-4.6l9.6,4.6C25.7,31,25.8,31,26,31h0h0h0c0.5,0,1-0.4,1-1c0-0.2,0-0.3-0.1-0.5l-2.8-10.3l6.6-7.6   C31,11.4,31.1,10.9,30.9,10.6z"
                                          fill="transparent"
                                          style={
                                            isActive === "N"
                                              ? { stroke: "#e1ae39" }
                                              : {
                                                  stroke: "#e1ae39",
                                                  fill: "#e1ae39",
                                                }
                                          }
                                        />
                                      </g>
                                      <g id="Film_1_" />
                                      <g id="Music" />
                                      <g id="Puzzle" />
                                      <g id="Turn_Off" />
                                      <g id="Book" />
                                      <g id="Save" />
                                      <g id="Reload" />
                                      <g id="Trash" />
                                      <g id="Tag" />
                                      <g id="Link" />
                                      <g id="Like" />
                                      <g id="Bad" />
                                      <g id="Gallery" />
                                      <g id="Add" />
                                      <g id="Close" />
                                      <g id="Forward" />
                                      <g id="Back" />
                                      <g id="Buy" />
                                      <g id="Mac" />
                                      <g id="Laptop" />
                                    </svg>
                                  )}
                                  {firstCoin}
                                </strong>
                                <font className="ng-binding">
                                  {" "}
                                  / {secondCoin}
                                </font>
                              </span>
                            </div>
                            <div className="box-kline-para-basic-right">
                              {
                                <TopBar
                                  topBarStatus={topBarStatus}
                                  binanceTicker={binanceTicker}
                                  topBarApiData={topBarApiData}
                                  secondCoin={secondCoin}
                                  exchange={exchange}
                                />
                              }
                              <div className="cls"></div>
                            </div>
                          </div>
                          <div
                            className="ne_box p-0 position-relative"
                            ref={chartRef}
                          >
                            {/* {exchange == "" ? ( */}
                            <TradingViewChart
                              params={params}
                              graphColor={themeRefresh}
                            />
                            {/* ) : (
                     <div className="binance-chart-container">
                       {openTradingChart(firstCoin + secondCoin)}
                     </div>
                   )} */}

                            {/* <div className="binance-chart-container">
                       {
                         <BinanceChart
                           symbol={params.firstCoin + params.secondCoin}
                         />
                       }
                     </div> */}
                          </div>

                          <div className="row tab-content h306">
                            <div className="col-md-6" id="buymobile">
                              <div className="ne_box h_100 ">
                                {" "}
                                <BuyBox
                                  firstCoinNew={firstCoinNew}
                                  firstCoin={firstCoin}
                                  secondCoin={secondCoin}
                                  secondCoinBalance={secondCoinBalance}
                                  removeTrailingZeros={removeTrailingZeros}
                                  setStopChanges={setStopChanges}
                                  setOrderMethod={setOrderMethod}
                                  limit={limit}
                                  market={market}
                                  handleBuySubmit={handleBuySubmit}
                                  stopLimit={stopLimit}
                                  stopPriceBuy={stopPriceBuy}
                                  handleBuyChange={handleBuyChange}
                                  orderMethod={orderMethod}
                                  disabledButton={disabledButton}
                                  order_id={order_id}
                                  handleKeyPress={handleKeyPress}
                                  type={type}
                                  currentPrice={currentPrice}
                                  buyPrice={buyPrice}
                                  buyAmount={buyAmount}
                                  bPBtn={bPBtn}
                                  buy_order_total={buy_order_total}
                                  buy_commission={buy_commission}
                                  removeTrailingZerosT={removeTrailingZerosT}
                                  feeAmyt={feeAmyt}
                                  token={token}
                                  disabledBuuButtn={disabledBuuButtn}
                                  setBuyPercentage={setBuyPercentage}
                                  setBuy_order_total={setBuy_order_total}
                                  setBuyAmount={setBuyAmount}
                                  setBPBtn={setBPBtn}
                                  setInExtype={setInExtype}
                                  setFeeAmyt={setFeeAmyt}
                                />
                              </div>
                            </div>
                            <div className="col-md-6" id="sellmobile">
                              <div className="ne_box h_100 ">
                                {" "}
                                <SellBox
                                  firstCoinNew={firstCoinNew}
                                  firstCoin={firstCoin}
                                  firstCoinBalance={firstCoinBalance}
                                  removeTrailingZeros={removeTrailingZeros}
                                  setStopChanges={setStopChanges}
                                  setOrderMethod={setOrderMethod}
                                  limit={limit}
                                  market={market}
                                  sell_commission={sell_commission}
                                  removeTrailingZerosT={removeTrailingZerosT}
                                  handleSellSubmit={handleSellSubmit}
                                  stopLimit={stopLimit}
                                  stopPriceSell={stopPriceSell}
                                  handleSellChange={handleSellChange}
                                  sellFeeAmyt={sellFeeAmyt}
                                  secondCoin={secondCoin}
                                  orderMethod={orderMethod}
                                  disabledButton={disabledButton}
                                  handleKeyPress={handleKeyPress}
                                  sell_order_id={sell_order_id}
                                  sellPrice={sellPrice}
                                  handleSellPercent={handleSellPercent}
                                  sPBtn={sPBtn}
                                  currentPrice={currentPrice}
                                  sellAmount={sellAmount}
                                  sell_order_total={sell_order_total}
                                  type={type}
                                  token={token}
                                  disabledSellButtn={disabledSellButtn}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 order-md-1">
                            <div className="ne_box h_100 ">
                              <h6>{t("Market Depth")}</h6>
                              <div className="bs_border" style={{overflow:"auto"}}>
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Price")}</th>
                                      <th>{t("Qty.")}</th>
                                      <th className="text-right">
                                        {t("Total")}({secondCoin})
                                      </th>
                                    </tr>
                                  </thead>
                                  {activeSellOrderHtml()}
                                </table>
                              </div>
                              <h6 className="text-center t2t m-0">
                                {" "}
                                {sideLastHtml()}
                              </h6>
                              <div className="bs_border" style={{overflow:"auto"}}>
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Price")}</th>
                                      <th>{t("Qty.")}</th>
                                      <th className="text-right">
                                        {t("Total")}({secondCoin})
                                      </th>
                                    </tr>
                                  </thead>
                                  {activeBuyOrderHtml()}
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-5">
                            <div className="ne_box h_100">
                              <div className="table_hadding flex-wrap ">
                                <h6 className="h6_head">{t("Orders")}</h6>
                                <div className="status-outer ">
                                  <ul className="nav nav-tabs2" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#Buytab"
                                        role="tab"
                                      >
                                        {t("Buy")}
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#Selltab"
                                        role="tab"
                                      >
                                        {t("Sell")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="buy-order-outer tab-content">
                                <div
                                  className="tab-pane active"
                                  id="Buytab"
                                  role="tabpanel"
                                >
                                  <div className="table_hadding flex-wrap table_hadding2">
                                    <h6 className="h6_head">
                                      {t("Buy Orders")}
                                    </h6>
                                    <div className="status-outer">
                                      <ul
                                        className="nav nav-tabs"
                                        role="tablist"
                                      >
                                        <li className="nav-item">
                                          <a
                                            className="nav-link active"
                                            data-toggle="tab"
                                            href="#pending"
                                            role="tab"
                                          >
                                            {t("Pending")}
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#complete"
                                            role="tab"
                                          >
                                            {t("Completed")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="buy-order-outer tab-content">
                                    <div
                                      className="tab-pane active"
                                      id="pending"
                                      role="tabpanel"
                                    >
                                      <div className="userRecords-main obo_exchange">
                                        <table className="table mb-0">
                                          <thead>
                                            <tr>
                                              <th className="f-left ng-binding">
                                                {t("Pair")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Price")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Amount")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Total")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Date & Time")}
                                              </th>
                                              <th className="f-center cancels">
                                                <span className="ng-binding p-0">
                                                  {t("Actions")}
                                                </span>
                                              </th>
                                            </tr>
                                          </thead>
                                          {myActiveBuyOrderHtml()}
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="complete"
                                      role="tabpanel"
                                    >
                                      <div className="userRecords-main">
                                        <table className="table mb-0">
                                          <thead>
                                            <tr>
                                              <th>{t("Pair")}</th>
                                              <th>{t("Price")}</th>
                                              <th>{t("Amount")}</th>
                                              <th>{t("Total")}</th>
                                              <th>{t("Date")}</th>
                                            </tr>
                                          </thead>
                                          {myCompletedBuyOrderHtml()}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="tab-pane "
                                  id="Selltab"
                                  role="tabpanel"
                                >
                                  <div className="table_hadding flex-wrap table_hadding2">
                                    <h6 className="h6_head">
                                      {t("Sell Orders")}
                                    </h6>
                                    <div className="status-outer">
                                      <ul
                                        className="nav nav-tabs"
                                        role="tablist"
                                      >
                                        <li className="nav-item">
                                          <a
                                            className="nav-link active"
                                            data-toggle="tab"
                                            href="#pending-sell"
                                            role="tab"
                                          >
                                            {t("Pending")}
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#complete-sell"
                                            role="tab"
                                          >
                                            {t("Completed")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="sell-order-outer tab-content">
                                    <div
                                      className="tab-pane active"
                                      id="pending-sell"
                                      role="tabpanel"
                                    >
                                      <div className="userRecords-main obo_exchange">
                                        <table className="table mb-0">
                                          <thead>
                                            <tr>
                                              <th className="f-left ng-binding">
                                                {t("Pair")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Price")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Amount")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Total")}
                                              </th>
                                              <th className="f-left ng-binding">
                                                {t("Date & Time")}
                                              </th>
                                              <th className="f-center cancels">
                                                <span className="ng-binding p-0">
                                                  {t("Actions")}
                                                </span>
                                              </th>
                                            </tr>
                                          </thead>
                                          {myActiveSellOrderHtml()}
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane"
                                      id="complete-sell"
                                      role="tabpanel"
                                    >
                                      <div className="userRecords-main">
                                        <table className="table mb-0">
                                          <thead>
                                            <tr>
                                              <th>{t("Pair")}</th>
                                              <th>{t("Price")}</th>
                                              <th>Amount</th>
                                              <th>Total</th>
                                              <th>Date</th>
                                            </tr>
                                          </thead>
                                          {myCompletedSellOrderHtml()}
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6  tab-pane">
                            <div className="ne_box h_100 br_0">
                              <div className="market-trade">
                                <div className="tradehistory tradehistory-new ">
                                  <div
                                    className="item-con tradeHistory-base"
                                    id="tradeHistory"
                                  >
                                    <h6 className="h6_head">
                                      {t("Order History")}
                                    </h6>
                                    <div className="scrollStyle ng-scope">
                                      <table className="table mb-0">
                                        <thead>
                                          <tr>
                                            <th>{t("Price")}</th>
                                            <th>{t("Amount")}</th>
                                            <th>{t("Pair")}</th>
                                            <th>{t("Date & Time")}</th>
                                          </tr>
                                        </thead>
                                        {marketTradeHtml()}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : tabSelect == "Markets" ? (
                        <>
                          {true ? (
                            <div className="row">
                              <div className="col-md-6 exchange_right order-md-2">
                                <div className="col-md-3 order-md-3 padding-right exchange_left">
                                  <div className="order-history-outer ne_box col-non-padding col-min-padding-right br_0">
                                    <div className="panel panel-default">
                                      <div className="panel-heading tab-box">
                                        <ul className="exchange_tab">
                                          {token && (
                                            <li
                                              className={
                                                setUrl === "fav"
                                                  ? "active tabQuoteAsset"
                                                  : "tabQuoteAsset"
                                              }
                                              onClick={() =>
                                                activeCurrency("fav")
                                              }
                                            >
                                              <i className="fa fa-star"></i>
                                            </li>
                                          )}
                                          {exchange_tab.map((list) => {
                                            return (
                                              <li
                                                key={list.id}
                                                className={
                                                  setUrl == list.short_name
                                                    ? "active tabQuoteAsset"
                                                    : "tabQuoteAsset"
                                                }
                                                onClick={() =>
                                                  activeCurrency(
                                                    list.short_name
                                                  )
                                                }
                                              >
                                                {list.short_name}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                        <div className="clearfix"></div>
                                      </div>
                                      <div className="panel-body p-0">
                                        <div className="searchFilter f-cb mo_none">
                                          <div className="search-box search-box-new">
                                            <input
                                              type="text"
                                              id="search-int"
                                              placeholder=""
                                              className="ng-pristine ng-untouched ng-valid ng-empty"
                                              onChange={onSearch}
                                              value={searchedValue}
                                            />
                                            {searchedValue !== "" ? (
                                              <i
                                                onClick={EmptySearch}
                                                className="las la-times-circle"
                                              ></i>
                                            ) : (
                                              <i className="las la-search"></i>
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div class="dropdown_currancy">
           <div type="button" class="dropdown-toggle dp_none mt-3" data-toggle="dropdown">
             Select Coin Pair
           </div>
           <div class="dropdown-menu"> */}
                                      <table className="table mb-0 currency_table1">
                                        <thead>
                                          <tr>
                                            <th
                                              className="f-left r-market-pair ng-binding"
                                              ng-click="sortByKey('baseAsset')"
                                            >
                                              {t("Pair")}
                                            </th>
                                            <th
                                              className="f-left r-market-price ng-binding"
                                              // onClick={() => sortCoin("price")}
                                              // style={{ cursor: "pointer" }}
                                            >
                                              {t("Price")}
                                              <span className="ng-scope">
                                                {/* {onPriceChange === true ? (
                     <i className="fa fa-caret-down"></i>
                   ) : (
                     <i className="fa fa-caret-up"></i>
                   )} */}
                                              </span>
                                            </th>

                                            <th
                                              className="r-market-rate ng-binding ng-scope tr"
                                              onClick={() => sortCoin("change")}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {t("Change")}
                                              <span className="ng-scope">
                                                {onChangeChange === true ? (
                                                  <i className="fa fa-caret-down"></i>
                                                ) : (
                                                  <i className="fa fa-caret-up"></i>
                                                )}
                                              </span>
                                            </th>
                                          </tr>
                                        </thead>
                                      </table>
                                      <div className="table-overflow table-overflow22">
                                        <table className="table table-hover currency_table">
                                          {coinListHtml_M(coinListData)}
                                        </table>
                                      </div>
                                      {/* </div>
         </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : tabSelect == "buy_sell" ? (
                        <>
                          <div className="col-md-6 exchange_right order-md-2">
                            <div className="m_h_100" ref={buy_sellRef}>
                              <div className="dp_none status-outer">
                                <ul class="nav nav-tabs2 ">
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active m-2"
                                      data-toggle="tab"
                                      href="#buymobile"
                                    >
                                      {t("Buy")}{" "}
                                      {firstCoinNew != ""
                                        ? firstCoinNew
                                        : firstCoin}
                                    </a>
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link m-2"
                                      data-toggle="tab"
                                      href="#sellmobile"
                                    >
                                      {t("Sell")}{" "}
                                      {firstCoinNew != ""
                                        ? firstCoinNew
                                        : firstCoin}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <div className="row tab-content h306">
                                <div className="col-md-6" id="buymobile">
                                  <div className="ne_box h_100 ">
                                    {
                                      <BuyBox
                                        firstCoinNew={firstCoinNew}
                                        firstCoin={firstCoin}
                                        secondCoin={secondCoin}
                                        secondCoinBalance={secondCoinBalance}
                                        removeTrailingZeros={
                                          removeTrailingZeros
                                        }
                                        setStopChanges={setStopChanges}
                                        setOrderMethod={setOrderMethod}
                                        limit={limit}
                                        market={market}
                                        handleBuySubmit={handleBuySubmit}
                                        stopLimit={stopLimit}
                                        stopPriceBuy={stopPriceBuy}
                                        handleBuyChange={handleBuyChange}
                                        orderMethod={orderMethod}
                                        disabledButton={disabledButton}
                                        order_id={order_id}
                                        handleKeyPress={handleKeyPress}
                                        type={type}
                                        currentPrice={currentPrice}
                                        buyPrice={buyPrice}
                                        buyAmount={buyAmount}
                                        bPBtn={bPBtn}
                                        buy_order_total={buy_order_total}
                                        buy_commission={buy_commission}
                                        removeTrailingZerosT={
                                          removeTrailingZerosT
                                        }
                                        feeAmyt={feeAmyt}
                                        token={token}
                                        disabledBuuButtn={disabledBuuButtn}
                                        setBuyPercentage={setBuyPercentage}
                                        setBuy_order_total={setBuy_order_total}
                                        setBuyAmount={setBuyAmount}
                                        setBPBtn={setBPBtn}
                                        setInExtype={setInExtype}
                                        setFeeAmyt={setFeeAmyt}
                                      />
                                    }
                                  </div>
                                </div>
                                <div className="col-md-6" id="sellmobile">
                                  <div className="ne_box h_100 ">
                                    {
                                      <SellBox
                                        firstCoinNew={firstCoinNew}
                                        firstCoin={firstCoin}
                                        firstCoinBalance={firstCoinBalance}
                                        removeTrailingZeros={
                                          removeTrailingZeros
                                        }
                                        setStopChanges={setStopChanges}
                                        setOrderMethod={setOrderMethod}
                                        limit={limit}
                                        market={market}
                                        sell_commission={sell_commission}
                                        removeTrailingZerosT={
                                          removeTrailingZerosT
                                        }
                                        handleSellSubmit={handleSellSubmit}
                                        stopLimit={stopLimit}
                                        stopPriceSell={stopPriceSell}
                                        handleSellChange={handleSellChange}
                                        sellFeeAmyt={sellFeeAmyt}
                                        secondCoin={secondCoin}
                                        orderMethod={orderMethod}
                                        disabledButton={disabledButton}
                                        handleKeyPress={handleKeyPress}
                                        sell_order_id={sell_order_id}
                                        sellPrice={sellPrice}
                                        handleSellPercent={handleSellPercent}
                                        sPBtn={sPBtn}
                                        currentPrice={currentPrice}
                                        sellAmount={sellAmount}
                                        sell_order_total={sell_order_total}
                                        type={type}
                                        token={token}
                                        disabledSellButtn={disabledSellButtn}
                                      />
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : tabSelect == "trades" ? (
                        <>
                          <div className="col-md-3 order-md-1">
                            <div className="ne_box h_100 ">
                              <h6>{t("Market Depth")}</h6>
                              <div className=" bs_border">
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Price")}</th>
                                      <th>{t("Qty.")}</th>
                                      <th className="text-right">
                                        {t("Total")}({secondCoin})
                                      </th>
                                    </tr>
                                  </thead>
                                  {activeSellOrderHtml()}
                                </table>
                              </div>
                              <h6 className="text-center t2t m-0">
                                {" "}
                                {sideLastHtml()}
                              </h6>
                              <div className="bs_border">
                                <table className="table mb-0 ">
                                  <thead>
                                    <tr>
                                      <th>{t("Price")}</th>
                                      <th>{t("Qty.")}</th>
                                      <th className="text-right">
                                        {t("Total")}({secondCoin})
                                      </th>
                                    </tr>
                                  </thead>
                                  {activeBuyOrderHtml()}
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="ne_box p-0 ">
                              <h6 className="h6_head pl-3">
                                {t("Spot") + " " + t("Balance")}
                              </h6>
                              <div
                                className="balance-outer"
                                style={{ overflow: "auto" }}
                              >
                                <table className="w-100">
                                  {showSpotBalance()}
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : tabSelect == "myorders" ? (
                        <>
                          <div className="color-background orderform-main">
                            <div className="row">
                              <div className="col-md-5" ref={myordersRef}>
                                <div className="ne_box h_100">
                                  <div className="table_hadding flex-wrap ">
                                    <h6 className="h6_head">{t("Orders")}</h6>
                                    <div className="status-outer ">
                                      <ul
                                        className="nav nav-tabs2"
                                        role="tablist"
                                      >
                                        <li className="nav-item">
                                          <a
                                            className="nav-link active"
                                            data-toggle="tab"
                                            href="#Buytab"
                                            role="tab"
                                          >
                                            {t("Buy")}
                                          </a>
                                        </li>
                                        <li className="nav-item">
                                          <a
                                            className="nav-link"
                                            data-toggle="tab"
                                            href="#Selltab"
                                            role="tab"
                                          >
                                            {t("Sell")}
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="buy-order-outer tab-content">
                                    <div
                                      className="tab-pane active"
                                      id="Buytab"
                                      role="tabpanel"
                                    >
                                      <div className="table_hadding flex-wrap table_hadding2">
                                        <h6 className="h6_head">
                                          {t("Buy Orders")}
                                        </h6>
                                        <div className="status-outer">
                                          <ul
                                            className="nav nav-tabs"
                                            role="tablist"
                                          >
                                            <li className="nav-item">
                                              <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#pending"
                                                role="tab"
                                              >
                                                {t("Pending")}
                                              </a>
                                            </li>
                                            <li className="nav-item">
                                              <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#complete"
                                                role="tab"
                                              >
                                                {t("Completed")}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="buy-order-outer tab-content">
                                        <div
                                          className="tab-pane active"
                                          id="pending"
                                          role="tabpanel"
                                        >
                                          <div className="userRecords-main obo_exchange">
                                            <table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th className="f-left ng-binding">
                                                    {t("Pair")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Price")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Amount")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Total")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Date & Time")}
                                                  </th>
                                                  <th className="f-center cancels">
                                                    <span className="ng-binding p-0">
                                                      {t("Actions")}
                                                    </span>
                                                  </th>
                                                </tr>
                                              </thead>
                                              {myActiveBuyOrderHtml()}
                                            </table>
                                          </div>
                                        </div>
                                        <div
                                          className="tab-pane"
                                          id="complete"
                                          role="tabpanel"
                                        >
                                          <div className="userRecords-main">
                                            <table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th>{t("Pair")}</th>
                                                  <th>{t("Price")}</th>
                                                  <th>{t("Amount")}</th>
                                                  <th>{t("Total")}</th>
                                                  <th>{t("Date")}</th>
                                                </tr>
                                              </thead>
                                              {myCompletedBuyOrderHtml()}
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="tab-pane "
                                      id="Selltab"
                                      role="tabpanel"
                                    >
                                      <div className="table_hadding flex-wrap table_hadding2">
                                        <h6 className="h6_head">
                                          {t("Sell Orders")}
                                        </h6>
                                        <div className="status-outer">
                                          <ul
                                            className="nav nav-tabs"
                                            role="tablist"
                                          >
                                            <li className="nav-item">
                                              <a
                                                className="nav-link active"
                                                data-toggle="tab"
                                                href="#pending-sell"
                                                role="tab"
                                              >
                                                {t("Pending")}
                                              </a>
                                            </li>
                                            <li className="nav-item">
                                              <a
                                                className="nav-link"
                                                data-toggle="tab"
                                                href="#complete-sell"
                                                role="tab"
                                              >
                                                {t("Completed")}
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="sell-order-outer tab-content">
                                        <div
                                          className="tab-pane active"
                                          id="pending-sell"
                                          role="tabpanel"
                                        >
                                          <div className="userRecords-main obo_exchange">
                                            <table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th className="f-left ng-binding">
                                                    {t("Pair")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Price")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Amount")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Total")}
                                                  </th>
                                                  <th className="f-left ng-binding">
                                                    {t("Date & Time")}
                                                  </th>
                                                  <th className="f-center cancels">
                                                    <span className="ng-binding p-0">
                                                      {t("Actions")}
                                                    </span>
                                                  </th>
                                                </tr>
                                              </thead>
                                              {myActiveSellOrderHtml()}
                                            </table>
                                          </div>
                                        </div>
                                        <div
                                          className="tab-pane"
                                          id="complete-sell"
                                          role="tabpanel"
                                        >
                                          <div className="userRecords-main">
                                            <table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th>{t("Pair")}</th>
                                                  <th>{t("Price")}</th>
                                                  <th>Amount</th>
                                                  <th>Total</th>
                                                  <th>Date</th>
                                                </tr>
                                              </thead>
                                              {myCompletedSellOrderHtml()}
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="color-background orderform-main">
                            <div className="row">
                              <div className="col-md-4  ">
                                <div className="ne_box h_100 br_0">
                                  <div className="market-trade">
                                    <div className="tradehistory tradehistory-new ">
                                      <div
                                        className="item-con tradeHistory-base"
                                        id="tradeHistory"
                                      >
                                        <h6 className="h6_head">
                                          {t("Order History")}
                                        </h6>
                                        <div className="scrollStyle ng-scope">
                                          <table className="table mb-0">
                                            <thead>
                                              <tr>
                                                <th>{t("Price")}</th>
                                                <th>{t("Amount")}</th>
                                                <th>{t("Pair")}</th>
                                                <th>{t("Date & Time")}</th>
                                              </tr>
                                            </thead>
                                            {marketTradeHtml()}
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  <Modal
                    className="modify_order"
                    show={isEditModelOpen}
                    onHide={closeEditModel}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title> Modify Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <input
                        type="number"
                        className="form-control  mb-3"
                        name="editPrice"
                        value={buyPrice}
                        onChange={onEditOrder}
                      />
                      <input
                        type="number"
                        className="form-control  mb-3"
                        name="editAmount"
                        value={buyAmount}
                        onChange={onEditOrder}
                      />
                      <button
                        onClick={handleBuySubmit}
                        className="btn w100 my_color_buy"
                      >
                        Update
                      </button>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    className="modify_order"
                    show={isEditModelOpenSell}
                    onHide={closeEditModel}
                    backdrop="static"
                    keyboard={false}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title> Modify Order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <input
                        type="number"
                        name="editPrice"
                        className="form-control mb-3"
                        value={sellPrice}
                        onChange={onEditOrderSell}
                      />
                      <input
                        type="number"
                        name="editAmount"
                        className="form-control  mb-3"
                        value={sellAmount}
                        onChange={onEditOrderSell}
                      />
                      <button
                        onClick={handleSellSubmit}
                        className="btn w100 my_color_sell"
                      >
                        Update
                      </button>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    size="lg"
                    fullscreen={true}
                    show={popUpModal}
                    onHide={closePopUpModel}
                    keyboard={false}
                  >
                    {/* <Modal.Header closeButton>

                        </Modal.Header> */}
                    <Modal.Body>
                      {popUpModalData.map((val, index) =>
                        val.view ? (
                          <div className="image_popup">
                            <p>
                              <span style={{ fontSize: "25px" }}>
                                {val.header}{" "}
                              </span>
                              <i
                                className="fa fa-close"
                                style={{
                                  float: "right",
                                  margin: "15px",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => closeImage(val, index)}
                              ></i>
                            </p>

                            <img
                              className=""
                              src={baseUrl + "/static/popups/" + val.logo}
                              alt="coin"
                              style={{ width: "100%" }}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div className="footer2">
          <div className="container">
            <div className="row row5">
              {/* <div className="col-3">
                <a onClick={() => showRef("markets", marketRef.current)} className={tabSelect == "markets" ? "active" : ""}>
                  <i className="fa fa-list-ul"></i> {t("Markets")}{" "}
                </a>
              </div> */}
              <div className="col-3">
                <a
                  onClick={() => showRef("Markets", chartRef.current)}
                  className={tabSelect == "Markets" ? "active" : ""}
                >
                  <i className="fa fa-line-chart"></i> {t("Markets")}{" "}
                </a>
              </div>
              <div
                className="col-3"
                onClick={() => showRef("Home", marketRef.current)}
              >
                <a className={tabSelect == "Home" ? "active" : ""}>
                  <i className="fa fa-list-ul"></i> {t("Home")}{" "}
                </a>
              </div>

              <div className="col-3">
                <a
                  onClick={() => showRef("buy_sell", buy_sellRef.current)}
                  className={tabSelect == "buy_sell" ? "active" : ""}
                >
                  <span className="responsive_item_gmx">
                    <img
                      className="img-fluid"
                      src="/assets/img/logo-icon-main.png"
                      alt="coin"
                    />
                  </span>{" "}
                  {t("Buy")}/{t("Sell")}{" "}
                </a>
              </div>
              <div className="col-3">
                <a
                  onClick={() => showRef("trades", tradesRef.current)}
                  className={tabSelect == "trades" ? "active" : ""}
                >
                  <i className="fa fa-history"></i> {t("Trades")}{" "}
                </a>
              </div>
              <div className="col-3">
                <a
                  onClick={() => showRef("myorders", myordersRef.current)}
                  className={tabSelect == "myorders" ? "active" : ""}
                >
                  <i className="fa fa-user"></i> {t("My Orders")}{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    </div>
  );
};

export default connect((state) => state)(ExchnageFuncSocket);
