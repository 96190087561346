import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import OtpInput from 'react-otp-input';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl, socketUrl } from "../Common/BaseUrl";

import authService from "../../services/auth.service";

function LoginPin(props) {
    const userData = (localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")) : null;
    const confirmPinRef = useRef(null);
    const checkPinRef = useRef(null);
    const history = useHistory();
    const { t } = useTranslation();
    const [date, setDate] = useState("")
    const [state, setState] = useState({ pin: "", newPin: "" })
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageData, setImageData] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [newPinErr, setNewPinErr] = useState("")
    const [confirmPinErr, setConfirmPinErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [popUpModalData, setPopUpModalData] = useState([])
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [popUpModal, setPopUpModal] = useState(false)
    const [verifyPin, setVerifyPin] = useState()

    useEffect(() => {
        //console.log(sessionStorage.getItem("verifyLoginPin"), " ", localStorage.getItem("token"))
        if (localStorage.getItem("userData")) {
            if (!sessionStorage.getItem("verifyLoginPin")) {
               // setPopUpModal(true)
            }
        }
    })

    const handleChange = (e) => {
        // console.log(e)
        // const { name, value } = e.target;
        // console.log(name);
        // console.log(value);
        setState({ ...state, pin: e })
        //  console.log(e.length)
        setDateErr("")
        if (e.length == 6) {
            let userData = JSON.parse(localStorage.getItem("userData"));
            console.log(e, " ", userData);
            if (userData.login_pin == undefined) {
                setDateErr("No Pin Found")
            }
            else {
                if (e == userData.login_pin) {
                   // console.log("veri");
                    toast.success(t("Pin Verified"));
                    sessionStorage.setItem("verifyLoginPin", true)
                    setPopUpModal(false)
                } else {
                    setDateErr("Incorrect Pin")
                   // setState({ ...state, pin: "" })
                   // checkPinRef.current.focus();
                }
            }

        }
    }

    const createPinChange = (e) => {
        console.log(e)
        setNewPinErr("")
        setState({ ...state, newPin: e, confirmPin: "" })
        console.log(e.length)
        if (e.length == 6) {
            console.log(confirmPinRef);
            confirmPinRef.current.focus();
        }

    }

    const confirmPinChange = (e) => {
        console.log(e)
        setConfirmPinErr("")
        if (state.newPin != "") {
            setState({ ...state, confirmPin: e })
            if (e.length == 6 && state.newPin.length == 6) {
                if (e != state.newPin) {
                    setConfirmPinErr("Confirm Pin and New pin not matched")
                } else {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    };
                    axios.post(baseUrl + "/frontapi/userloginpincreate", { pin: e }, config)
                        .then((res) => {
                            console.log("res=> ", res);
                            if (res.data.status) {
                                toast.success(t("PIN set successfully"));
                                authService.getUserDetail()
                                sessionStorage.setItem("verifyLoginPin", true)
                                setPopUpModal(false)
                            }
                        }).catch((err) => {

                        });
                }

            }
            console.log(e.length)
        } else {
            setNewPinErr("Please Enter New pin")
        }

    }

    const handleSubmit = () => {
        console.log("click");
        // setDescriptionErr("")
        if (state.pin === "") {
            // setDateErr("This field is required")
            return false
        }


        console.log(state.pin, " ", userData.login_pin);
        if (userData.login_pin) {
            if (state.pin == userData.login_pin) {
                console.log("veri");
                sessionStorage.setItem("verifyLoginPin", true)
                setPopUpModal(false)
            } else {
                setDateErr("Incorrect Pin")
            }
        } else {

        }
        //formdata.append("link", blogLink)

    }
    const forgotPin = () => {
        history.push("/forgot-pin");
    }
    const closePopUpModel = () => {

    }

    const logout = () => {
        authService.logout();
        toast.success(t("Logout Successfully"));
        history.push("/");
        // let interval = setInterval(() => {
        //     var theme = localStorage.getItem("theme");
        //     localStorage.clear();
        //     sessionStorage.clear();
        //     localStorage.setItem("theme", theme);
        //     history.push("/login-to-buy-sell-crypto");
        //     clearInterval(interval);
        // }, 1000);
    };


    return (
        <div>
 {(userData && userData.kycVerification_status == "Y" && userData.password != null) ? 
<>
            <Modal
                fullscreen={true}
                show={popUpModal}
                onHide={closePopUpModel}
                keyboard={false}
            >
                <Modal.Header>
                    <Link to="#" className="a_flex ml-auto" onClick={logout}>
                        <i className="fa fa-sign-out mr-2"></i>{t("Logout")}
                    </Link>
                </Modal.Header>
                <Modal.Body>

                    <>

                        {(userData && userData?.login_pin) ?
                            <>
                                <div className="modal-header" style={{ textAlign: "center", display: "block" }}>
                                    <h4 className="modal-title">{t("Confirm Pin")}</h4>
                                </div>
                                <div className="modal-body">

                                    <div className='mb-3 pin-box'>
                                        {/* <div className='mb-2'>
                                            <label className='form-label'>Pin:</label></div> */}
                                        <OtpInput
                                            containerStyle={{ display: "block" }}
                                            value={state.pin}
                                            onChange={(e) => handleChange(e)}
                                            numInputs={6}                                            
                                            renderSeparator={<span>&nbsp;&nbsp;</span>}
                                            renderInput={(props) => <input {...props} type="password" style={{ width: "1.5rem", textAlign: "center" }}                                             
                                            />}
                                        />
                                        <div style={{ color: "red" }}>{dateErr}</div>
                                    </div>
                                    {/* <input type="Number" name="pin" value={state.pin} onChange={handleChange} className='form-control' /> */}
                                     <div className='text-center'>
                                    <Link to="#" className="a_flex" onClick={forgotPin}>
                                    {t("forgot Pin")} <i class="fa fa-key" aria-hidden="true"></i>
                                    </Link>
                                    </div>






                                </div>
                                {/* <div className="modal-footer">
                                    <button className="btn btn_man" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div> */}
                            </>
                            :
                            <>
                                <div className="modal-header" style={{ textAlign: "center", display: "block" }}>
                                    <h4 className="modal-title">{t("Create Pin")}</h4>
                                </div>
                                <div className="modal-body">

                                    <div className='mb-3 pin-box'>
                                        <label className='form-label'>{t("Set Pin")}:</label>
                                        <OtpInput
                                            containerStyle={{ display: "block" }}
                                            value={state.newPin}
                                            onChange={(e) => createPinChange(e)}
                                            numInputs={6}
                                            renderSeparator={<span>&nbsp;&nbsp;</span>}
                                            renderInput={(props) => <input {...props} type="password" style={{ width: "1.5rem", textAlign: "center" }} />}
                                        />
                                        {/* <input type="Number" name="pin" value={state.pin} onChange={handleChange} className='form-control' /> */}
                                        <span style={{ color: "red" }}>{newPinErr}</span>


                                    </div>

                                    <div className='mb-3 pin-box'>
                                        <label className='form-label'>{t("Confirm Pin")}:</label>
                                        <OtpInput
                                            containerStyle={{ display: "block" }}
                                            value={state.confirmPin}
                                            onChange={(e) => confirmPinChange(e)}
                                            numInputs={6}                                           
                                            renderSeparator={<span>&nbsp;&nbsp;</span>}
                                            renderInput={(props) => <input {...props} type="password" style={{ width: "1.5rem", textAlign: "center" }} />}
                                            ref={confirmPinRef} />
                                        {/* <input type="Number" name="pin" value={state.pin} onChange={handleChange} className='form-control' /> */}
                                        <span style={{ color: "red" }}>{confirmPinErr}</span>


                                    </div>


                                </div>
                                {/* <div className="modal-footer">
                                    <button className="btn btn_man" onClick={handleSubmit}>
                                        Submit
                                    </button>
                                </div> */}
                            </>


                        }
                    </>

                </Modal.Body>
            </Modal>
            <ToastContainer autoClose={3000} />
            </>
            :""}
        </div>
    )
}

export default LoginPin
