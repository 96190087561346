    import React, { useEffect, useState, useRef } from "react";
    import axios from "axios";
    import Moment from "moment";
    import socketIOClient from "socket.io-client";
    import { ToastContainer, toast } from "react-toastify";
    import "react-toastify/dist/ReactToastify.css";
    import { Link, useParams, useHistory } from "react-router-dom";
    import Tab from 'react-bootstrap/Tab';
    import Tabs from 'react-bootstrap/Tabs';
    import { confirmAlert } from "react-confirm-alert";
    import { baseUrl, socketUrl } from "./Common/BaseUrl";
    import LoginHeader from "./Common/LoginHeader";
    import DocumentMeta from "react-document-meta";
    import { ProductName } from "./Common/ProductName";


    import { useTranslation } from "react-i18next";
    // import { stat } from "fs";

    let interId = null;

    const CoinExchange = () => {
        const history = useHistory();
        const { t } = useTranslation();
        const params = useParams();
        const clientRef = useRef(null);
        const [state, setState] = useState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_name: null, token_address: "" });
        // const [state, setCoin] = useState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_current_price: 0, coin_name: null });

        const [selectedCoin, setSelectedCoin] = useState({ name: "" });
        const [selectedUpcomingCoin, setSelectedUpcomingCoin] = useState({ name: "" });

        const [coinList, setCoinList] = useState([]);
        const [userCoins, setUserCoins] = useState([]);
        const [transactions, setTransactions] = useState([]);
        const [coinPairs, setCoinPairs] = useState([]);
        const [tokenList, setTokenList] = useState({ current: [], upcoming: [] });
        const [dbiUsdtCoin, setDbiUsdtCoin] = useState({});
        const [userUsdtBalance, setUserUsdtBalance] = useState(0);

        const [userQty, setUserQty] = useState("");
        const [buttonDisable, setButtonDisable] = useState(0);
        const [errorState, setErrorState] = useState({ coin_select: "", quantity: "" });
        const [referedUser, setReferedUser] = useState({});
        const [transactionTotal, setTransactionTotal] = useState(0);

        const binanceWebsocket = useRef(null);
        const [refresh, setRefresh] = useState(false);
        const [coinListData, setCoinListData] = useState([]);
        const [setUrl, setSetUrl] = useState("");
        const [buyAmount, setBuyAmount] = useState("");
        const [sellAmount, setSellAmount] = useState("");
        const [sell_order_total, setSell_order_total] = useState("");
        const [buy_order_total, setBuy_order_total] = useState("");
        const [setActiveTab, setSetActiveTab] = useState("USDT");
        const [exchange_tab, setExchange_tab] = useState([]);
        const [exchange, setExchange] = useState("");
        const [searchedValue, setSearchedValue] = useState("");
        const [onPriceChange, setOnPriceChange] = useState(true);
        const [onChangeChange, setOnChangeChange] = useState(true);
        const [coinListStatus, setCoinListStatus] = useState(false);
        const [firstCoin, setFirstCoin] = useState("");
        const [secondCoin, setSecondCoin] = useState("");
        const [screenWidth, setScreenWidth] = useState(window.innerWidth);
        const [activeBuyOrder, setActiveBuyOrder] = useState(false);
        const [activeBuyOrderData, setActiveBuyOrderData] = useState([]);
        const [activeSellOrder, setActiveSellOrder] = useState(false);
        const [activeSellOrderData, setActiveSellOrderData] = useState([]);
        const [livPrice, setLivPrice] = useState("0:00");
        const [binanceTicker, setBinanceTicker] = useState([]);
        const [topBarStatus, setTopBarStatus] = useState(false);
        const [binanceTrades, setBinanceTrades] = useState([]);
        const [binanceDepth, setBinanceDepth] = useState([]);
        const [currentPrice, setCurrentPrice] = useState("");
        const [marketTrade, setMarketTrade] = useState(false);
        const [marketTradeData, setMarketTradeData] = useState([]);
        const [topBarApiData, setTopBarApiData] = useState([]);
        const [myActiveBuyOrder, setMyActiveBuyOrder] = useState(false);
        const [myActiveBuyOrderData, setMyActiveBuyOrderData] = useState([]);
        const [myActiveSellOrder, setMyActiveSellOrder] = useState(false);
        const [myActiveSellOrderData, setMyActiveSellOrderData] = useState([]);
        const [myCompletedBuyOrder, setMyCompletedBuyOrder] = useState(false);
        const [myCompletedBuyOrderData, setMyCompletedBuyOrderData] = useState([]);
        const [myCompletedSellOrder, setMyCompletedSellOrder] = useState(false);
        const [myCompletedSellOrderData, setMyCompletedSellOrderData] = useState([]);
        const [firstCoinBalance, setFirstCoinBalance] = useState(0);
        const [secondCoinBalance, setSecondCoinBalance] = useState(0);
        const [mySpotBalance, setMySpotBalance] = useState([]);
        const [portfolioData, setPortfolioData] = useState("");
        const [key, setKey] = useState('current');
        const token = localStorage.getItem("token");
        const streams = ["@ticker", "@depth20", "@trade"];
        const [SKey, setSKey] = useState("");

        useEffect(() => {
            getList()
            //   walletAllCoinBalance();
            getTransactionList();
            getData()
            getCoinPriceList("DBI");
        }, [])

        useEffect(() => {
            // console.log(state.coin_name, " = ", interId)
            if (state.coin_name) {
                if (interId) {
                    //  clearInterval(interId)
                }
                // if (state.coin_name != "USDT") {
                //     console.log("cronstyart");
                //     interId = setInterval(() => { getCoinPriceList(state.coin_name) }, 5000);
                // }
                //getCoinPriceList(state.coin_name)
            }
        }, [state.coin_name])

        // console.log("userfeect");
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        const getData = () => {
            axios.post(baseUrl + "/frontapi/sale_market_token", {}, config).then((res) => {
                if (res.data.status) {
                    setTokenList(res.data.data)
                    setSKey(res.data.data.wallet_address)
                    setSKey(res.data.data.wallet_address)
                    if (res.data.data.coin_balance.length) {
                        // console.log(res.data.data.coin_balance[0].coin_amount);
                        if (res.data.data.coin_balance[0].coin_amount) {
                            setUserUsdtBalance(res.data.data.coin_balance[0].coin_amount)
                        }
                    }
                }
            }).catch((err) => {

            })
        }

        const sortCoin = (type) => {
            if (type === "price") {
                setOnPriceChange(!onPriceChange);
            }
            if (type === "change") {
                setOnChangeChange(!onChangeChange);
            }
        };

        const activeCurrency = (value) => {
            console.log("val=> ", value);
            localStorage.setItem("setUrl", value);
            setSetUrl(value);
            setBuyAmount("");
            setSellAmount("");
            setSell_order_total(0);
            setBuy_order_total(0);
            setSetActiveTab(value);
        };


        const onSearch = (event) => {
            setSearchedValue(event.target.value);
        };

        const EmptySearch = () => {
            setSearchedValue("");
        };

        const coinListHtml_M = (coinListData) => {
            try {
                // console.log("coinListData => ", coinListData);
                var listData = [];
                if (coinListStatus === true && coinListData.length > 0) {
                    listData = coinListData;
                }
                var filteredItems = listData.filter((item) =>
                    item.first_coin_name.includes(searchedValue.toUpperCase())
                );

                let tableHtml = filteredItems.map((item, index) => {
                    var percentShow =
                        ((parseFloat(item.current_price) -
                            parseFloat(item.oneday_before_price)) *
                            100) /
                        item.oneday_before_price;
                    percentShow = percentShow.toFixed(2);
                    var showActive =
                        firstCoin === item.first_coin_name &&
                        secondCoin === item.second_coin_name;

                    var lastPrice = parseFloat(item.current_price); // Get the last price from the item object
                    var decimalPlaces = lastPrice < 1 ? 8 : 2; // Determine the number of decimal places based on the value of the number
                    var formattedPrice = lastPrice.toLocaleString("en-US", {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    });
                    formattedPrice = item.current_price == null ? 0 : formattedPrice;
                    if (setActiveTab == "fav" && item.activeStatus == "YES") {
                        // console.log("1 setActiveTab =-----> ");
                        return (
                            <tr
                                key={index}
                                className={showActive ? "ng-scope active" : "ng-scope"}
                            >
                                {(screenWidth > 766) ?
                                    <Link
                                        to={
                                            "/listing/" +
                                            item.first_coin_name +
                                            "/" +
                                            item.second_coin_name
                                        }
                                        onClick={() =>
                                            dataOnClick(
                                                item.first_coin_name,
                                                item.second_coin_name,
                                                item.isBinance
                                            )
                                        }
                                    >
                                        <td className="r-market-pair ng-binding fw600">
                                            {item.icon ? (
                                                <img
                                                    className="currency_icon"
                                                    src={baseUrl + "/static/currencyImage/" + item.icon}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    className="currency_icon"
                                                    src="/assets/img/btc.png"
                                                    alt=""
                                                />
                                            )}
                                            {item.first_coin_name}
                                            <small>/{item.second_coin_name}</small>
                                        </td>
                                        <td className="r-market-price ng-binding fw600">
                                            {item.current_price}
                                        </td>
                                        <td className="r-market-rate ng-scope tr">
                                            <span
                                                className="ng-binding ng-scope green"
                                                style={
                                                    percentShow < 0 ? { color: "red" } : { color: "green" }
                                                }
                                            >
                                                {isNaN(percentShow) ? "0.00" : percentShow}%
                                            </span>
                                        </td>
                                    </Link>
                                    :
                                    <a onClick={() => clickCurrency(item)}>

                                        <td className="r-market-pair ng-binding fw600">
                                            {item.icon ? (
                                                <img
                                                    className="currency_icon"
                                                    src={baseUrl + "/static/currencyImage/" + item.icon}
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    className="currency_icon"
                                                    src="/assets/img/btc.png"
                                                    alt=""
                                                />
                                            )}
                                            {item.first_coin_name}
                                            <small>/{item.second_coin_name}</small>
                                        </td>
                                        <td className="r-market-price ng-binding fw600">
                                            {item.current_price}
                                        </td>
                                        <td className="r-market-rate ng-scope tr">
                                            <span
                                                className="ng-binding ng-scope green"
                                                style={
                                                    percentShow < 0 ? { color: "red" } : { color: "green" }
                                                }
                                            >
                                                {isNaN(percentShow) ? "0.00" : percentShow}%
                                            </span>
                                        </td>
                                    </a>}
                            </tr>
                        );
                    }
                    if (item.second_coin_name == setActiveTab) {
                        // console.log("2 setActiveTab =-----> ");
                        return (
                            <tr
                                key={index}
                                className={showActive ? "ng-scope active" : "ng-scope"}>

                                <Link
                                    to={
                                        "/listing/" +
                                        item.first_coin_name +
                                        "/" +
                                        item.second_coin_name
                                    }
                                    onClick={() =>
                                        dataOnClick(
                                            item.first_coin_name,
                                            item.second_coin_name,
                                            item.isBinance
                                        )
                                    }
                                >
                                    <td className="r-market-pair ng-binding fw600">
                                        {item.icon ? (
                                            <img
                                                className="currency_icon"
                                                src={baseUrl + "/static/currencyImage/" + item.icon}
                                                alt=""
                                            />
                                        ) : (
                                            <img
                                                className="currency_icon"
                                                src="/assets/img/btc.png"
                                                alt=""
                                            />
                                        )}
                                        {item.first_coin_name}
                                        <small>/{item.second_coin_name}</small>
                                    </td>
                                    <td className="r-market-price ng-binding fw600">
                                        {item.current_price}
                                    </td>
                                    <td className="r-market-rate ng-scope tr">
                                        <span
                                            className="ng-binding ng-scope green"
                                            style={
                                                percentShow < 0 ? { color: "red" } : { color: "green" }
                                            }
                                        >
                                            {isNaN(percentShow) ? "0.00" : percentShow}%
                                        </span>
                                    </td>
                                </Link>



                            </tr>
                        );
                    }
                });
                //  console.log(tableHtml);
                return tableHtml
            } catch (e) {
                console.log(e);
            }
        };

        const dataOnClick = (firstCoin, secondCoin, isBinance) => {
            console.log("dataOnClick=> ", firstCoin, secondCoin, isBinance);
            if (isBinance === true) {
                if (binanceWebsocket.current) {
                    binanceWebsocket.current.close();
                }
                let data = firstCoin + secondCoin;
                let symbol = data.toLowerCase();
                _connectSocketStreams(streams.map((i) => `${symbol}${i}`));
                setExchange("binance");
            } else {
                setExchange("");
                if (binanceWebsocket.current) {
                    binanceWebsocket.current.close();
                }
            }

            if (params.firstCoin !== firstCoin) {
                // clientRef.current.disconnect();
                const pairData = {
                    firstCoin: firstCoin,
                    secondCoin: secondCoin,
                };
                //clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
                // clientRef.current.emit("orderBookData", pairData); // Emit an event to request market data for the pair
                // clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
                clientRef.current.emit("coinPairList");
                handleSocketData(
                    clientRef.current,
                    clientRef.current.id,
                    firstCoin,
                    secondCoin
                );
                setRefresh(!refresh);
            }
            localStorage.setItem("tab", setActiveTab);
        };

        const clickCurrency = (value) => {
            console.log("val=> ", value);
            if (screenWidth > 766) {
                console.log("dataOnClick=> ");
                dataOnClick(value.first_coin_name, value.second_coin_name, value.isBinance)

            } else {
                history.push("/exchange-market/" + value.first_coin_name + "/" + value.second_coin_name + "");
            }
        };

        const secondConstructor = async (obj) => {
            return {
                cost: obj.p,
                amount: obj.q,
                timestamp: obj.T,
            };
        };

        const _connectSocketStreams = (streams) => {
            //console.log(streams);
            let newArr = [];
            // Close the previous WebSocket connection
            if (binanceWebsocket.current) {
                binanceWebsocket.current.close();
            }
            try {
                streams = streams.join("/");
                // Create a new WebSocket connection
                binanceWebsocket.current = new WebSocket(
                    `wss://stream.binance.com:9443/stream?streams=${streams}`
                );
                binanceWebsocket.current.onmessage = async (evt) => {
                    const eventData = JSON.parse(evt.data);
                    if (eventData.stream.endsWith("@ticker")) {
                        const obj = eventData.data;
                        setLivPrice(obj.c);
                        setBinanceTicker(obj);
                        setTopBarStatus(true);
                    }

                    if (eventData.stream.endsWith("@trade")) {
                        const mainValue = eventData.data;
                        const data = await secondConstructor(mainValue);
                        newArr.push(data);
                        if (newArr.length > 10) {
                            newArr.shift();
                        }
                        const binanceTrades = { binanceTrades: newArr };
                        const trades = binanceTrades.binanceTrades;
                        setBinanceTrades(trades);
                    }

                    if (eventData.stream.endsWith("@depth20")) {
                        setBinanceDepth(eventData.data);
                    }
                };

                binanceWebsocket.current.onerror = (evt) => {
                    console.error(evt);
                };
            } catch (error) {
                console.log("err2", error);
            }
        };

        const handleSocketData = (socket, id, firstCoin, secondCoin) => {
            console.log("userfeect1244444");
            socket.off(`marketDataPairApi_${firstCoin}_${secondCoin}`);
            socket.on(`marketDataPairApi_${firstCoin}_${secondCoin}`, (socketData) => {
                const { buyOrderData, sellOrderData } = socketData;
                const activeBuyOrderData = buyOrderData;
                const activeSellOrderData = sellOrderData;
                if (activeBuyOrderData.status) {
                    setActiveBuyOrder(activeBuyOrderData.status);
                    setActiveBuyOrderData(activeBuyOrderData.data);
                } else {
                    setActiveBuyOrder(activeBuyOrderData.status);
                    setActiveBuyOrderData([]);
                }
                if (activeSellOrderData.status) {
                    setActiveSellOrder(activeSellOrderData.status);
                    setActiveSellOrderData(activeSellOrderData.data);
                } else {
                    setActiveSellOrder(activeSellOrderData.status);
                    setActiveSellOrderData([]);
                }
            });

            socket.off(`orderBookDataApi_${firstCoin}_${secondCoin}`);
            socket.on(`orderBookDataApi_${firstCoin}_${secondCoin}`, (orderData) => {
                const { data, status, lastPrice } = orderData;
                setCurrentPrice(lastPrice);
                setMarketTrade(status);
                setMarketTradeData(data);
            });

            socket.off(`topBarDataApi_${firstCoin}_${secondCoin}`);
            socket.on(`topBarDataApi_${firstCoin}_${secondCoin}`, (topBarData) => {
                const { data, status } = topBarData;
                setTopBarApiData(data);
                setTopBarStatus(status);
            });

            socket.off("coinPairListApi");
            socket.on("coinPairListApi", (coinListData) => {
                const { data, status } = coinListData;
                // console.log("new data ",data);
                setCoinListData(data);
                setCoinListStatus(status);
            });
            socket.off(`resultPairBinance_${firstCoin}_${secondCoin}`);
            socket.on(`resultPairBinance_${firstCoin}_${secondCoin}`, (topBarData) => {
                const { data, status } = topBarData;
                if (status === true) {
                    setExchange(data.exchange);
                } else {
                    setExchange("");
                }
            });
            socket.off("refresh");
            socket.on("refresh", () => {
                const pairData = {
                    firstCoin: firstCoin,
                    secondCoin: secondCoin,
                };

                clientRef.current.emit("marketDataPair", pairData); // Emit an event to request market data for the pair
                clientRef.current.emit("orderBookData", pairData); // Emit an event to request order book data for the pair
                clientRef.current.emit("topBarData", pairData); // Emit an event to request market data for the pair
                clientRef.current.emit("coinPairList");
                clientRef.current.emit("checkPairBinance", pairData); // Emit an event to request top bar data for the pair
                handleSocketData(
                    clientRef.current,
                    clientRef.current.id,
                    firstCoin,
                    secondCoin
                );
                setRefresh(!refresh);

                const token = localStorage.getItem("token");
                if (token) {
                    subscribeToUserStreams(clientRef.current, firstCoin, secondCoin);
                }
            });
        };

        const subscribeToUserStreams = (socket, firstCoin, secondCoin) => {
            const pairData = {
                firstCoin: firstCoin,
                secondCoin: secondCoin,
            };
            socket.emit("userPendingOrders", pairData);
            socket.emit("userCompleteOrders", pairData);
            socket.emit("userBalanceByPair", pairData);
            socket.emit("mySpotBalance", pairData);
            socket.emit("userRealTimePortfolio", pairData);

            handleUserSocketResp(socket);
        };

        const handleUserSocketResp = (socket) => {
            socket.off("userPendingOrdersApi");
            socket.on("userPendingOrdersApi", (pendingOrders) => {
                const { buyOrderData, sellOrderData } = pendingOrders;

                if (buyOrderData.status) {
                    setMyActiveBuyOrder(buyOrderData.status);
                    setMyActiveBuyOrderData(buyOrderData.data);
                } else {
                    setMyActiveBuyOrder(buyOrderData.status);
                    setMyActiveBuyOrderData([]);
                }
                if (sellOrderData.status) {
                    setMyActiveSellOrder(sellOrderData.status);
                    setMyActiveSellOrderData(sellOrderData.data);
                } else {
                    setMyActiveSellOrder(sellOrderData.status);
                    setMyActiveSellOrderData([]);
                }
            });

            socket.off("userCompleteOrdersApi");
            socket.on("userCompleteOrdersApi", (completedOrders) => {
                const { buyOrderData, sellOrderData } = completedOrders;

                if (buyOrderData.status) {
                    setMyCompletedBuyOrder(buyOrderData.status);
                    setMyCompletedBuyOrderData(buyOrderData.data);
                } else {
                    setMyCompletedBuyOrder(buyOrderData.status);
                    setMyCompletedBuyOrderData([]);
                }
                if (sellOrderData.status) {
                    setMyCompletedSellOrder(sellOrderData.status);
                    setMyCompletedSellOrderData(sellOrderData.data);
                } else {
                    setMyCompletedSellOrder(sellOrderData.status);
                    setMyCompletedSellOrderData([]);
                }
            });

            socket.off("userBalanceByPairApi");
            socket.on("userBalanceByPairApi", (userBalanceByPairApi) => {
                const { firstCoinBalance, secondCoinBalance } = userBalanceByPairApi;
                setFirstCoinBalance(firstCoinBalance);
                setSecondCoinBalance(secondCoinBalance);
            });

            socket.off("mySpotBalanceApi");
            socket.on("mySpotBalanceApi", (mySpotBalanceApi) => {
                setMySpotBalance(mySpotBalanceApi);
            });

            socket.off("userRealTimePortfolioApi");
            socket.on("userRealTimePortfolioApi", (portfolioBalc) => {
                setPortfolioData(portfolioBalc);
            });
        };

        const selectCoin = (val) => {
            // console.log(val);
            let newdate = new Date();

            setErrorState({ coin_select: "", quantity: "" })
            if (val.date_of_live_str < newdate.getTime() / 1000) {
                val.lastPrice = val.price;

                // setErrorState({ coin_select: "", quantity: "" })
            } else {
                // setSelectedCoin({ name: "" });
                // setSelectedUpcomingCoin(val)
                toast.success(t("Launching Soon"));
            }
            setSelectedCoin(val)
        }


        const handleChange = (e) => {
            // console.log(e.target.value);
            // console.log(e.target.name);
            let errorTemp = {};
            if (e.target.name == "coin_one") {
                let avail_coin = 0;
                let totalQty = 0;
                let coinCurrentPrice = 0;
                let totalAmount = 0;
                let coin_name = null;
                let sValue = coinList.find((val) => val.currency_one_id == 7);
                console.log("sValue ", sValue);

                let sCoinDetail = coinList.find((val) => val.currency_one_id == e.target.value);
                coin_name = sCoinDetail.first_coin_sort
                if (e.target.value != 7) {

                    //  console.log("sCoinDetail ", sCoinDetail);
                    if (sCoinDetail) {
                        //let setCoinDetail  = 


                        // getCoinPriceList(sCoinDetail.first_coin_sort)
                        // console.log(sCoinDetail.first_coin_sort);
                        let coinDetail = userCoins.find((val) => val.coin_id == e.target.value);
                        // console.log("cd ", coinDetail.getValue.main[0].coin_amount);
                        if (coinDetail.getValue.main[0].coin_amount != null) {
                            avail_coin = coinDetail.getValue.main[0].coin_amount;
                        }
                        // console.log("available_coin ", avail_coin);
                        totalQty = sValue.qty_two * state.qty;
                        let pairPrice = coinPairs.find((val) => val.symbol == sCoinDetail.first_coin_sort + "USDT");
                        if (pairPrice) {
                            coinCurrentPrice = pairPrice.lastPrice;
                            // console.log(pairPrice.lastPrice);
                            sCoinDetail.lastPrice = pairPrice.lastPrice;
                        }
                        setSelectedCoin(sCoinDetail)
                    }
                    totalAmount = totalQty * coinCurrentPrice;
                } else {
                    totalQty = sValue.qty_two * state.qty;
                    totalAmount = totalQty;
                    setSelectedCoin({ lastPrice: 1 })
                }


                // console.log("coin_name ", coin_name);
                setState({ ...state, [e.target.name]: e.target.value, coin_qty: sValue.qty_two, available_coin: avail_coin, total_qty: totalQty, total_amount: totalAmount, coin_current_price: coinCurrentPrice, coin_name: coin_name, coin_one: e.target.value })

            } else if (e.target.name == "qty") {
                let quantityError = "";
                //  console.log(selectedCoin);
                // if (e.target.value != "") {

                // let lastPrice = (selectedCoin.price) ? selectedCoin.price : 0;
                setUserQty(e.target.value)
                let totalQty = 0;
                let totalAmount = 0;
                if (e.target.value < "0") {
                    quantityError = "Please enter Valid Quantity";
                }
                else if (e.target.value > 0) {
                    totalQty = state.coin_qty * e.target.value;
                    totalAmount = selectedCoin.price * e.target.value;
                    // console.log(e.target.value);
                    // if (e.target.value <= state.available_coin) {
                    //  console.log("yes");


                    if (e.target.value < selectedCoin.user_min_limit) {

                        quantityError = "Entered Quantity must be more then " + selectedCoin.user_min_limit;
                    }
                    else if (e.target.value > selectedCoin.user_limit) {

                        quantityError = "Entered Quantity not available";
                    } else if (userUsdtBalance < e.target.value) {
                        quantityError = "Quantity is not more then USDT balance.";
                    }

                }
                setState({ ...state, [e.target.name]: e.target.value, total_qty: totalQty, total_amount: totalAmount })

                if (e.target.value != "" && quantityError != "") {
                    errorTemp.quantity = quantityError;
                    //  setErrorState({ ...errorState, quantity: quantityError })
                }
                // }else{
                //     console.log("else");
                //     e.preventDefault()
                // }
                // } else {
                // setState({ ...state, [e.target.name]: "" })
                //}
            } else if (e.target.name == "token_address") {
                setState({ ...state, [e.target.name]: e.target.value })
                if (e.target.value) {
                    //setErrorState({ ...errorState, token_address: "" })

                }
            }
            else if (e.target.name == "referal_code") {
                //setErrorState({ ...errorState, referal_code: "" })
                setState({ ...state, [e.target.name]: e.target.value })
                setReferedUser({})
                console.log(e.target.value.length)
                if (e.target.value) {
                    if (e.target.value.length >= 7) {
                        console.log("api")
                        checkReferal(e.target.value)
                    } else {
                        errorTemp.referal_code = "Entered Sponser is not valid."
                    }
                }
            }

            setErrorState(errorTemp)
        }

        const walletAllCoinBalance = () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            axios
                .get(baseUrl + "/frontapi/wallet_Coin_Total_Balance", config)
                .then((res) => {
                    console.log(res);
                    if (res.data.status) {
                        let pairPrice = res.data.data.find((val) => val.coin_id == 7);
                        if (pairPrice.getValue.spot.length) {
                            console.log(pairPrice.getValue.spot[0].coin_amount);
                            if (pairPrice.getValue.spot[0].coin_amount) {
                                setUserUsdtBalance(pairPrice.getValue.spot[0].coin_amount)
                            }
                        }
                        setUserCoins(res.data.data)

                    }
                });
        };

        const getList = () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let sendData = {};
            axios.post(baseUrl + "/frontapi/token_exchange_list", sendData, config).then((res) => {
                //console.log(res);
                if (res.data.status) {
                    setCoinList(res.data.data)

                }
            })
        }

        const getCoinPriceList = (coinName) => {
            // console.log(coinName, " = ", interId);
            //let temp = {...state}
            if (coinName) {
                // let temp = {...selectedCoin}
                const config = {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                };
                axios.get(baseUrl + "/api/pair-data", config).then((res) => {
                    // console.log(res.data);
                    // setCoinPairs(res.data.data.body);
                    let pairPrice = res.data.find((val) => val.id == 235);
                    //     let pairPrice = res.data.data.body.find((val) => val.symbol == coinName + "USDT");
                    //  console.log(pairPrice);
                    //  setDbiUsdtCoin(pairPrice)
                    //     if (pairPrice) {
                    //   let coinCurrentPrice = pairPrice.lastPrice;
                    // setSelectedCoin({ ...selectedCoin, lastPrice: coinCurrentPrice })

                    // let totalQty = state.coin_qty * (userQty != "" ? userQty : 0);
                    // let totalAmount = totalQty * coinCurrentPrice;
                    // console.log(e.target.value);
                    // if (e.target.value <= state.available_coin) {
                    // console.log("yes", state);
                    // setState({ ...state, total_amount: totalAmount })
                    // setState({ ...state, coin_current_price: coinCurrentPrice })
                    //  }


                })
                //  setTimeout(() => { getCoinPriceList(selectedCoin) }, 5000);
            }

        }

        // setInterval(() => { getCoinPriceList() }, 5000);

        const getTransactionList = () => {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let sendData = {};
            axios.post(baseUrl + "/frontapi/token_exchange_transactions", sendData, config).then((res) => {
              //  console.log(res);
                if (res.data.status) {
                    setTransactions(res.data.data)
                  //  console.log(res.data.data);
                    const totalAmount = res.data.data.reduce((accumulator, item) => { return accumulator + (item.dbi_rate * item.quantity) }, 0)
                    //console.log(totalAmount);
                    setTransactionTotal(totalAmount)

                }
            })
        }

        const keyCopy = () => {
            //console.log("click ", SKey);
            navigator.clipboard.writeText(SKey)
            toast.success(t("Copied to clipboard"));
        }

        const checkReferal = (value) => {
            setReferedUser({})
            setErrorState({});
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let sendData = { refer_id: value }
            // console.log(sendData)
            axios.post(baseUrl + "/frontapi/check_valid_sponsors", sendData, config).then((res) => {
                console.log("res=> ", res);
                if (res.data.status) {
                    setReferedUser(res.data.data[0])
                } else {
                    setErrorState({ ...errorState, referal_code: res.data.message })
                }
            }).catch((e) => {
                console.log("error=> ", e);
            })
        }

        const confirm_button = () => {
            //  console.log(state)
            // console.log(selectedCoin)
            //setErrorState({})
            let flag = 1;
            let error = { ...errorState };
            if (!selectedCoin.id) {
                flag = 0;
                console.log("enert");
                error = { ...error, coin_select: "Please Select Coin" };
            }
            if (state.token_address == "") {
                // flag = 0;
                //error = { ...error, token_address: "Enter Your Transaction Hash" };
            } else if (state.token_address.length < 10) {
                flag = 0;
                error = { ...error, token_address: "Please Enter valid transaction address" };
            }
            if (userQty == "" || userQty == "0") {
                flag = 0;
                error = { ...error, quantity: "Please enter Quantity" };
            }
            else if (userQty < "0") {
                flag = 0;
                error = { ...error, quantity: "Please enter Valid Quantity" };
            }
            if (userUsdtBalance < userQty) {
                error = { ...error, quantity: "Quantity is not more then USDT balance." };
            }
            else {
                if (userQty < selectedCoin.user_min_limit) {
                    flag = 0;
                    error = { ...error, quantity: "Entered Quantity must be more then " + selectedCoin.user_min_limit };
                }
                if (userQty > selectedCoin.user_limit) {
                    flag = 0;
                    error = { ...error, quantity: "Entered Quantity not available" };
                }
            }
            if (errorState.referal_code) {
                flag = 0;
                error = { ...error, referal_code: errorState.referal_code };
            }

            // console.log(error, Object.keys(errorState).length);
            // console.log(flag);
            setErrorState(error)
            //  console.log(error);

            if (flag && Object.keys(error).length == 0) {
                confirmAlert({
                    title: "Confirm to Submit",
                    message: "Are you sure to do this.",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => submit(),
                        },
                        {
                            label: "No",
                            onClick: () => { },
                        },
                    ],
                });
            }
        }

        const submit = () => {

            clearInterval(interId)
            setButtonDisable(1)
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            let sendData = state;
            sendData.qty = userQty;
            sendData.coin_one = selectedCoin.id;
            sendData.total_amount = selectedCoin?.price * userQty;
            sendData.referal_user_id = (referedUser.id) ? referedUser.id : null;
            //state?.coin_qty * userQty * selectedCoin?.lastPrice
            console.log(sendData)
            axios.post(baseUrl + "/frontapi/userCoinExchangeBuy", sendData, config).then((res) => {
                console.log("res=> ", res);
                if (res.data.status) {
                    toast.success(t(res.data.message));
                    setUserQty("")
                    setState({ coin_one: "", coin_two: 46, qty: 0, coin_qty: 0, total_qty: 0, available_coin: 0, total_amount: 0, coin_name: null, token_address: "" })
                    setSelectedCoin({ name: "" })
                    setButtonDisable(0)
                    // window.location.reload();
                    getTransactionList()
                    // walletAllCoinBalance();
                } else {
                    setButtonDisable(0)
                    toast.error(res.data.message);
                }
            }).catch((e) => {
                console.log("error=> ", e);
            })


        }

        const timeDiff = (time) => {
            // console.log("tt=> ", time);
            let days = 0;
            let remainingHours = 0;
            let minutes = 0;
            if (time) {
                let newDate = new Date();
                let timeStr = parseInt(newDate.getTime() / 1000);
               // console.log(timeStr);

                if (time > timeStr) {
                    const total = time - timeStr;
                   // console.log("total ",total);
                    days = Math.floor(total / (60 * 60 * 24));
                    var remainingSecondsAfterDays = total % 86400;

                    remainingHours = Math.floor(remainingSecondsAfterDays / 3600); // 1 hour = 3600 seconds
                    var remainingSecondsAfterHours = remainingSecondsAfterDays % 3600;

                     minutes = Math.floor(remainingSecondsAfterHours / 60); // 1 minute = 60 seconds
                    var seconds = remainingSecondsAfterHours % 60;
                }
            }
            return days +" Days "+ remainingHours +" Hours " + minutes+ " Minutes "+  seconds + " seconds";
        }

        const showTableHtml = (value) => {

            // console.log(value);

            let senddata = [];

            if (value.length == 0) {

            } else {
                value.forEach((e, i) => {
                    senddata.push(
                        <tr>
                            <td className="">
                                {e.sno}
                            </td>
                            <td className="">
                                {e.token_name}
                            </td>
                            <td className="">
                                {(e.coin_name != "Binance") ? e.coin_name : "BNB"}
                            </td>
                            <td className="">
                                {e.quantity * e.dbi_rate}
                            </td>
                            <td className="">
                                {e.sno}
                            </td>


                            <td className="">
                                {Moment(e.date).format("lll")}
                            </td>
                            <td className="">
                                {timeDiff(e.progress_completed_date)}
                            </td>
                            <td className="">
                                {t(e.transaction_status)}
                            </td>
                        </tr>)
                })
            }

            return <tbody className="">{senddata}</tbody>
        }

        const meta = {
            title: `coinExchange | ${ProductName}  Exchange`,
            description: `Login on ${ProductName}  Exchange now and start trading! Invite your friends to ${ProductName}  Exchange.`,
            meta: {
                charset: "utf-8",
                name: {
                    keywords: `${ProductName}  Exchange , Crypto Exchange, Buy Sell Crypto, Bitcoin Exchange India`,
                },
            },
        };
        const selectOption = (val) => {
            setKey(val);
            unSelectToken();
        }
        const unSelectToken = () => {
            setSelectedCoin({ name: "" })
            setSelectedUpcomingCoin({ name: "" })
            setUserQty("")
        }

        return (
            <DocumentMeta {...meta}>
                <div>
                    <LoginHeader />
                    <div className="">
                        <div className="bg_light">
                            <div className="new_inpage">
                                <div className="container container2">
                                    {/* <LeftTab /> */}
                                    <div className="white_box dashbord_in dashbord_in_top fs12 mb-4">
                                        <h5 class="">{t("Coin")} {t("Exchange")}</h5>
                                        <div className="row">


                                            <div className="col-md-12 col-lg-12 ">
                                                <div className="coin_tab mb-4 d-sm-flex   align-items-center">
                                                    <p className="mr-0 ml-auto mt-0 order-sm-3 d-block d-sm-inline">
                                                        <label className="fw-500">{t("Total DBI Token")}:</label>
                                                        <span className="color1 fs18"> {transactionTotal}</span>
                                                    </p>
                                                    <p className="mr-0 ml-auto mt-0 order-sm-3 d-block d-sm-inline">
                                                        <label className="fw-500">USDT {t("Balance")}:</label>
                                                        <span className="color1 fs18"> {userUsdtBalance}</span>
                                                    </p>

                                                    <p className={"default-token mt-0 " + (key == "current" ? "selected-token" : "")} onClick={() => { selectOption("current") }}>{t("Current")}</p>

                                                    <p className={"default-token  mt-0 " + (key == "upcoming" ? "selected-token" : "")} onClick={(k) => { selectOption("upcoming") }}>{t("Upcoming")}</p>
                                                </div>
                                            </div>

                                            {(selectedCoin.name == "") ?
                                                <div className="col-md-12 col-lg-12">

                                                    {(key == "current") ?
                                                        <div className="row">

                                                            {tokenList.current.map((val, index) =>
                                                                <div className="col-md-3 col-xs-6 coin_in_box mb-3">
                                                                    <div className={"card cursor-pointer h100 " + (val.id == selectedCoin.id ? "selected-token" : "")} onClick={() => selectCoin(val)}>
                                                                        <div className="" role="tab">
                                                                            <div className="img_coin" role="tab">
                                                                                <img className="img-fluid" src={baseUrl + "/static/market_token/" + val.symbol} />
                                                                            </div>
                                                                            <div>{val.name}</div>
                                                                            <div>{t("Price")}: {val.price}</div>


                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            )

                                                            }
                                                        </div>
                                                        :
                                                        <div className="row">

                                                            {
                                                                tokenList.upcoming.map((val, index) =>
                                                                    <div className="col-md-3 col-xs-3 coin_in_box mb-3">
                                                                        <div className="card cursor-pointer h100" onClick={() => selectCoin(val)}>
                                                                            <div className="" role="tab" id={"headingOne" + index}>
                                                                                <img className="img-fluid" src={baseUrl + "/static/market_token/" + val.symbol} />
                                                                                <div>{val.name}</div>
                                                                                <div>{t("Price")}: {val.price}</div>


                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                )


                                                            }
                                                        </div>

                                                    }
                                                </div>
                                                : <>
                                                    {(key == "current") ?
                                                        <div className="col-lg-8 col-lg-8 coin_in_box">
                                                            <span className="mb-2 cursor-pointer d-inline-block" onClick={() => unSelectToken()}> <i className="fa fa-arrow-left"></i></span>
                                                            <div className={"card"}>
                                                                <div className="p-1" role="tab">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-md-4 ">
                                                                            <img className="img-fluid rd10" src={baseUrl + "/static/market_token/" + selectedCoin.symbol} />
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="about_scrool">
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Token Name")}: </span>{selectedCoin.name}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Total Discounted DBI Price")}: </span>{selectedCoin.price}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("User Minimum limit")}:</span> {selectedCoin.user_min_limit}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("User Maximum limit")}:</span> {selectedCoin.user_limit}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Live Date")}:</span> {Moment(selectedCoin.date_of_live).format("MMM DD yyy")}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Expiry Date")}:</span> {Moment(selectedCoin.date_of_expire).format("MMM DD yyy")}</div>
                                                                                <div className="mb-2 d-md-flex"><span class="wcoin_t mr-auto">
                                                                                    {/* <i class="fa fa-globe" aria-hidden="true"></i> */}
                                                                                    White Paper Link:
                                                                                </span> <br /><a href={selectedCoin.link} class="" target="_blank">{selectedCoin.link}</a>
                                                                                </div>
                                                                                <div className="mb-2 as_scrool"><span class="wcoin_t">{t("Description")}:</span> {selectedCoin.description}</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div> :
                                                        <div className="col-lg-8 col-lg-8 coin_in_box">
                                                            <span className="mb-2 cursor-pointer d-inline-block" onClick={() => unSelectToken()}> <i className="fa fa-arrow-left"></i></span>
                                                            <div className={"card"}>
                                                                <div className="p-1" role="tab">
                                                                    <div className="row align-items-center">
                                                                        <div className="col-md-4 ">
                                                                            <img className="img-fluid rd10" src={baseUrl + "/static/market_token/" + selectedCoin.symbol} />
                                                                        </div>
                                                                        <div className="col-md-8 ">
                                                                            <div className="about_scrool">
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Token Name")}: </span>{selectedCoin.name}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Total Discounted DBI Price")}: </span>{selectedCoin.price}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Per User Minimum limit")}:</span> {selectedCoin.user_min_limit}</div>

                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Per User Maximum limit")}:</span> {selectedCoin.user_limit}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Launching Date")}:</span> {Moment(selectedCoin.date_of_live).format("MMM DD yyy")}</div>
                                                                                <div className="mb-2 d-flex"><span class="wcoin_t mr-auto">{t("Expiry Date")}:</span> {Moment(selectedCoin.date_of_expire).format("MMM DD yyy")}</div>
                                                                                <div className="mb-2 d-md-flex"><span class="wcoin_t mr-auto">
                                                                                    {/* <i class="fa fa-globe" aria-hidden="true"></i>  */}
                                                                                    White Paper Link:
                                                                                </span><br /><a href={selectedCoin.link} class="" target="_blank">{selectedCoin.link}</a>
                                                                                </div>

                                                                                <div className="mb-2 as_scrool"><span class="wcoin_t">{t("Description")}:</span> {selectedCoin.description}</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>}
                                                    {(key == "current") ?
                                                        <div className="col-lg-4 col-lg-4">

                                                            {/* <div className="form-group">
                                                                <label className="fw-500">{t("Coin")}</label>
                                                                <input className="form-control" type="text" name="cname" onChange={handleChange} value={selectedCoin?.name} placeholder={t("Select Token")} disabled={true} />

                                                                <span style={{ "color": "red" }}>{t(errorState.coin_select)}</span> */}
                                                            {/* {selectedCoin?.lastPrice} */}
                                                            {/* </div> */}


                                                            <div className="form-group">
                                                                <label className="fw-500">{t("Quantity")}</label>
                                                                <div className=" d-flex">

                                                                    <input className="form-control" type="number" name="qty" onChange={handleChange} disabled={!selectedCoin.quantity} value={userQty} placeholder={t("Enter Quantity")} />

                                                                </div>
                                                                <span style={{ "color": "red" }}> {t(errorState.quantity)}</span>
                                                            </div>

                                                            <div className="form-group">
                                                                <div className=" d-flex mt-3 form-control">

                                                                    {(coinList.length > 0) ?
                                                                        <>
                                                                            <span className="mr-auto">{coinList[0].second_coin_sort}</span>

                                                                            <span className="color1">{state.total_amount}</span></> : ""}

                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className=" d-flex mt-3 form-control">

                                                                    <>

                                                                        <span className="mr-auto">{t("Start Date")}</span>
                                                                        <span className="color1">{Moment(Date.now()).format("MMM DD yyy")}</span></>



                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <div className=" d-flex mt-3 form-control">

                                                                    <>

                                                                        <span className="mr-auto">{t("Expiry Date")}</span>
                                                                        <span className="color1">{Moment(Date.now() + (86400 * 300 * 1000)).format("MMM DD yyy")}</span></>


                                                                </div>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                <label className="fw-500 mr-auto">{t("Wallet Address")}</label>
                                                                <div className="d-flex form-control">

                                                                    {(SKey != "") ? <span className="color1"> {SKey} <i className="fa fa-copy ml-2 clip-copy" onClick={keyCopy}></i></span> : ""}

                                                                </div>
                                                            </div> */}
                                                            {/* <div className="form-group">
                                                                <label className="fw-500 mr-auto">{t("Transaction Hash")}</label>
                                                                <div className=" d-flex">
                                                                    <input className="form-control" type="text" name="token_address" onChange={handleChange} disabled={!selectedCoin.quantity} value={state.token_address} placeholder={t("Enter Your Transaction Hash")} />


                                                                </div>
                                                                <span style={{ "color": "red" }}>{t(errorState.token_address)}</span>
                                                            </div> */}

                                                            <div className="form-group">
                                                                <label className="fw-500 mr-auto">{t("Sponsor Code")}</label>
                                                                <div className=" d-flex">
                                                                    <input className="form-control" type="text" name="referal_code" onChange={handleChange} disabled={!selectedCoin.quantity} value={state.referal_code} placeholder={t("enter")+" "+t("Sponsor Code")} />


                                                                </div>
                                                                {(errorState.referal_code) ?
                                                                    <span style={{ "color": "red" }}>{t(errorState.referal_code)}</span> :
                                                                    (state.referal_code && referedUser.id) ?
                                                                        <>  <span style={{ "color": "green" }}> Valid Refer code</span></> : ""

                                                                }
                                                            </div>

                                                            <div className="form-group">
                                                                <button className="btn btn_man" onClick={confirm_button} type="button" disabled={buttonDisable}>{t("submit")}</button>
                                                            </div>
                                                        </div>
                                                        : ""}

                                                </>}
                                        </div>
                                    </div>
                                    <div className="tab-content white_box">
                                        <div className="tab-pane active" id="Balance">
                                            <div className="top_balance"></div>
                                            <div className="rows">
                                                <div className="wallet_box">
                                                    <div className="table-responsive">
                                                        <div className="scrool_wallet_box">
                                                            <table className="table table-striped mb-0 ">
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("Sr. No.")}</th>
                                                                        <th>{t("Token Name")}</th>
                                                                        <th>{t("Coin")}</th>
                                                                        <th>{t("Amount")}</th>

                                                                        <th className="">
                                                                            {t("Transaction ID")}
                                                                        </th>


                                                                        <th>{t("Created At")}</th>
                                                                        <th>{t("Days Left")}</th>
                                                                        <th>{t("Status")}</th>
                                                                    </tr>
                                                                </thead>
                                                                {showTableHtml(transactions || [])}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </DocumentMeta>
        );
    };

    export default CoinExchange;
