import axios from "axios";
import React from "react";
import { withTranslation } from 'react-i18next';
import DocumentMeta from "react-document-meta";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "../../Common/BaseUrl";
import LoginPin from "../../Common/LoginPin";
import PopUpModals from "./PopUpModals";

class Header extends React.Component {

  constructor(props) {
    super(props);
    const userData = (localStorage.getItem("userData")) ? JSON.parse(localStorage.getItem("userData")) : null;
    console.log(userData);
    this.state = {
     // activePair: "/BTC/USDT",
      activePair :localStorage.getItem("excoin1") ? localStorage.getItem("excoin1") + "/" + localStorage.getItem("excoin2") : null,
      popUpModalData: [],
      popUpModal: false,
      userData:userData,
      sessionValue: localStorage.getItem("homepopshow")
    };
  }

  componentDidMount() {
    this.getOpenPair();
    this.getPopList();
  }

  getOpenPair = () => {
    if(!localStorage.getItem("excoin1")){
    axios
      .get(baseUrl + "/api/get-open-pair")
      .then((res) => {
        if (res.data.status) {
          this.setState({
            activePair: `${res.data.data[0].first_name}/${res.data.data[0].second_name}`,
          });
        }
      })
      .catch((err) => { });
    }
  }

  getPopList = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(baseUrl + "/frontapi/popUplist", { type: "web", lang: localStorage.getItem("lang") }, config)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          this.setState({ popUpModalData: res.data.data });
          //setPopModalData(res.data.data)
          let newDate = new Date();
          let timeStamp = parseInt(newDate.getTime() / 1000)
         // console.log(this.state.sessionValue);
          if (res.data.data.length > 0 && (this.state.sessionValue == null || timeStamp >= this.state.sessionValue)) {
            this.setState({ popUpModal: true });
          }
        }
        //setExchange_tab(res.data);
      }).catch((e) => {
        console.log(e);
      });
  }

  closePopUpModel = () => {
    console.log("clicked");
    let newDate = new Date();
    let timeStamp = parseInt(newDate.getTime() / 1000)
    timeStamp += 86400;
    localStorage.setItem("homepopshow", timeStamp);
    this.setState({ popUpModal: false });
    //setPopModalOpen(false);
  }
  closeImage = (val, index) => {
    let tempPopup = this.state.popUpModalData;
    console.log("clicked",val);
   // val.view = false;
     console.log(this.state.popUpModalData[index]);
    let i = 0;
    //tempPopup[index] = val;
    tempPopup.map((ele,ind) => {
      console.log(ele);
      if(index == ind) {
      ele.view = false;
      }
      console.log(ele);
      if (!ele.view) {
        i += 1;
      }
    })
    this.state.popUpModalData = tempPopup;
     console.log("close ",i," ",this.state.popUpModalData.length);
    if (i == this.state.popUpModalData.length) {
     // this.closePopUpModel()
    }
  }

  render() {
    const { t } = this.props;
    const meta = {
      title: "Buy Bitcoin, Trading Platform | DBI  Exchange",
      description:
        "DBI  Exchange is Most Secure Exchange Platform. Buy And Sell With Multi Currency Exchange With DBI  Exchange's Official Website.",
      meta: {
        charset: "utf-8",
        name: {
          keywords:
            "DBI  Exchange , Exchange, Buy Sell, Bitcoin Exchange India",
        },
      },
    };
    return (
      <DocumentMeta {...meta}>
        <LoginPin />
        <header className="home" id="home">
          <div className="container">
            <div className="home-outer position-relative">
              <div className="row align-items-center">
                <div className="col-md-6 col-12 order-md-2 mb-4 mb-md-0">
                  <div className="banner-right mt-5 pl-md-5">
                    <img
                      src="assets/img/coin-banner-1.png"
                      alt=""
                      className="img-fluid bb_img"
                    />

                  </div>
                </div>
                <div className="col-md-6 col-12  order-md-1">
                  <div className="banner-left">
                    <h1>
                      {t("Experience Seamless Trading with DBI")}
                    </h1>
                    <p>
                      {t("DBI delivers a hassle-free  trading experience with cutting-edge technology. Our platform offers secure transactions and efficient trading tools so that you can manage your  investments with ease.")}
                    </p>
                    <div className="banner-btn-outer">
                      <Link
                        to={`/exchange/${this.state.activePair}`}
                        className="btn btn_man"
                      >
                        {t("Trade Now")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopUpModals/>
          {/* <Modal
            size="lg"
            fullscreen={true}
            show={this.state.popUpModal}
            onHide={this.closePopUpModel}
            keyboard={false}
          >
            {/* <Modal.Header closeButton>

                        </Modal.Header> 
            <Modal.Body>
              
              {this.state.popUpModalData.map((val, index) =>
                (val.view) ? <div className="image_popup" key={index}>
                  <p><span style={{ fontSize: "25px" }}>{val.header} </span><i className="fa fa-close" style={{ float: "right", margin: "15px", cursor: "pointer" }} onClick={(e) => this.closeImage(val, index)}></i></p>

                  <img
                    className=""
                    src={baseUrl + "/static/popups/" + val.logo}
                    alt="coin"
                    style={{ width: "100%" }}
                  />
                </div> : "")}
            </Modal.Body>
          </Modal> */}
        </header>
      </DocumentMeta>
    );
  }
}

export default withTranslation()(Header);
