import React from "react";
import Ticker from "./Ticker";
import { useTranslation } from "react-i18next";
import { formatDecimal } from "../../utils/decimalFormatter";

function TopBar({
  topBarStatus,
  binanceTicker,
  topBarApiData,
  secondCoin,
  exchange,
}) {
  const { t } = useTranslation();
  const topBarHtml = () => {
    var collectHtml;
    //  console.log("top");
    if (topBarStatus !== false) {
      if (exchange === "binance") {
        return (
          <ul>
            <Ticker {...binanceTicker} />
          </ul>
        );
      } else {
        const topBarData = topBarApiData;

        collectHtml = (
          <ul>
            <li>
              <h4 className="ng-binding">{t("Last Price")}</h4>
              <strong className="ng-binding">
                {secondCoin === "INR" ? (
                  <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
                ) : (
                  <span></span>
                )}
                {topBarData.currentPrice > 1
                  ? formatDecimal(topBarData.currentPrice)
                  : formatDecimal(topBarData.currentPrice)}
              </strong>
              <strong className="transMoney ng-binding ng-scope"></strong>
            </li>
            <li>
              <h4 className="ng-binding">{t("24h-Change")}</h4>
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
              topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope ">
                    {"0.00"}
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePriceAmount == null ? (
                    <strong className="changeRate ng-binding ng-scope ">
                      {formatDecimal(topBarData.changePriceAmount)}
                    </strong>
                  ) : (
                    <strong
                      className={`${
                        topBarData.changePriceAmount < 0
                          ? "changeRate ng-binding ng-scope text-danger"
                          : "changeRate ng-binding ng-scope "
                      }`}
                    >
                      {formatDecimal(topBarData.changePriceAmount)}
                    </strong>
                  )}
                </>
              )}
              {(topBarData.oneDayMaxPrice === null &&
                topBarData.oneDayMinPrice === null) ||
              topBarData.oneDayMaxPrice === topBarData.oneDayMinPrice ? (
                <>
                  <strong className="changeRate ng-binding ng-scope green">
                    {" "}
                    {0}%
                  </strong>
                </>
              ) : (
                <>
                  {topBarData.changePricePercent == null ||
                  topBarData.changePricePercent.toFixed(2) == null ? (
                    <strong className="changeRate ng-binding ng-scope ">
                      {formatDecimal(topBarData.changePricePercent)}%
                    </strong>
                  ) : (
                    <strong
                      className={`${
                        topBarData.changePricePercent < 0 ? "text-danger" : ""
                      }`}
                    >
                      {" "}
                      ({formatDecimal(topBarData.changePricePercent)}%)
                    </strong>
                  )}
                </>
              )}
            </li>
            <li>
              <h4 className="ng-binding">24h-High</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMaxPrice == null
                  ? topBarData.currentPrice > 1
                    ? formatDecimal(topBarData.currentPrice)
                    : formatDecimal(topBarData.currentPrice)
                  : formatDecimal(topBarData.oneDayMaxPrice)}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h-Low</h4>
              <strong className="ng-binding">
                {topBarData.oneDayMinPrice == null
                  ? topBarData.currentPrice > 1
                    ? formatDecimal(topBarData.currentPrice)
                    : formatDecimal(topBarData.currentPrice)
                  : formatDecimal(topBarData.oneDayMinPrice)}
              </strong>
            </li>
            <li>
              <h4 className="ng-binding">24h Volume</h4>
              <strong className="ng-binding">
                {topBarData.oneDayVolume == null
                  ? "0.00"
                  : formatDecimal(topBarData.oneDayVolume)}
              </strong>
            </li>
          </ul>
        );
      }

      return <ul>{collectHtml}</ul>;
    } else {
      collectHtml = (
        <ul>
          <li>
            <h4 className="ng-binding">{t("Last Price")}</h4>
            <strong className="ng-binding">
              {secondCoin === "INR" ? (
                <span style={{ color: "#07bc8d", fontWeight: "500" }}>₹</span>
              ) : (
                <span></span>
              )}
              {0.0}
            </strong>
            <strong className="transMoney ng-binding ng-scope"></strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Change</h4>
            <strong className="changeRate ng-binding ng-scope green">
              0.00
            </strong>{" "}
            <strong className="changeRate ng-binding ng-scope green">
              (0.00)%
            </strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-High</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h-Low</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
          <li>
            <h4 className="ng-binding">24h Volume</h4>
            <strong className="ng-binding">0.00</strong>
          </li>
        </ul>
      );
      return <ul>{collectHtml}</ul>;
    }
  };
  return <div>{topBarHtml()}</div>;
}

export default TopBar;